<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        v-if="!isAutoInput"
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1 && !isAutoInput"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-checkbox
        v-if="index > -1"
        slot="func-option"
        v-model="goNextCourtDay"
        dense
        label="다음기일추가"
        class="mt-3"
      ></v-checkbox>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="article.teamName"
                label="담당부서"
                readonly
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="article.teamColorOrigin"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="8">
              <!-- <v-text-field
                v-model.trim="form.str1"
                v-validate="'required|max:30'"
                :error-messages="errors.collect('str1')"
                data-vv-name="str1"
                maxlength="30"
                counter="30"
                required
                label="기일명*"
                hint="예)변론기일"
              ></v-text-field> -->
              <!-- !! 기일명 자동완성. -->
              <v-combobox
                v-model="form.str1"
                :filter="ackFilter"
                :items="keyword.fDate"
                :search-input.sync="sStr1"
                :append-icon="!sStr1 ? 'mdi-arrow-down-drop-circle' : 'mdi-plus-circle-outline'"
                :menu-props="{ maxHeight: 340 }"
                v-validate="'required|max:30'"
                :error-messages="errors.collect('str1')"
                data-vv-name="str1"
                required
                label="기일명*"
                maxlength="30"
                hint="예)변론기일"
                class="mt-0"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item }}</span>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-select
                v-model="form.gubun2"
                :items="subtype1Gubun2"
                label="예정"
                dense
                menu-props="auto"
                class="ml-2 mt-4"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-menu
                ref="date1Selector"
                v-model="date1Selector"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model.trim="form.date1"
                    label="일자"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    style="width: 200px;"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="form.date1"
                  locale="ko"
                  @change="saveDate1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="3"  class="text-right">
              <v-menu
                ref="timeSelector"
                v-model="timeSelector"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="time"
                    :disabled="noTime"
                    label="시간"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                    style="width: 150px"
                    class="ml-5"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeSelector"
                  v-model="time"
                  :disabled="noTime"
                  format="24hr"
                  @click:minute="$refs.timeSelector.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="5" class="text-left">
              <!-- <v-switch
                v-model="noTime"
                color="primary"
                :label="noTime ? '시간없음' : '시간있음'"
                class="mt-5 ml-10"
                @click.stop="chNoTime"
              ></v-switch> -->
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str3"
                label="장소"
                maxlength="50"
                counter="50"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str2"
                label="결과"
                maxlength="30"
                counter="30"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txt1"
                v-model="form.txt1"
                outlined
                label="기일준비사항"
                placeholder="입력하세요"
                no-resize
                rows="5"
                class="mt-1"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txt2"
                v-model="form.txt2"
                outlined
                label="의뢰인주장"
                placeholder="입력하세요"
                no-resize
                rows="5"
                class="mt-1"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txt3"
                v-model="form.txt3"
                outlined
                label="상대방주장"
                placeholder="입력하세요"
                no-resize
                rows="5"
                class="mt-1"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txt4"
                v-model="form.txt4"
                outlined
                label="재판부판단"
                placeholder="입력하세요"
                no-resize
                rows="5"
                class="mt-1"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ article.courtName }} {{ article.caseNum }}{{ article.manageNum ? `/${article.manageNum}` : '' }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <!-- <v-col cols="12" v-show="index > -1" class="py-0">
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0"
              >
                <v-card-actions class="ma-0 pa-0">
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>print</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>get_app</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>phone_iphone</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>local_post_office</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <span class="text-caption mr-2">{{ viewWname }}</span><span class="text-caption">{{ viewUpdatedAt }}</span>
                </v-card-actions>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import sleep from '@/lib/sleep'
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값
      type: 21, // 중요: 유형 - 진행내용
      depth: 2,
      pId: 0,
      pType: 1, // 중요: 부모타입 = 소송
      pInfo: '', // 정보위치(부모의 정보 중 보여줄 정보)
      isStar: 0,
      isSchedule: 1,
      noTime: 0, // !![2021.4.23] 시간없음 없음
      subtype: 1, // 중요: 기본값은 1(기일)
      gubun1: '', // 기일,기한 같은 타이틀 명
      gubun2: '', // 기일 - 예정
      status: 1,
      date1: '', // 업무일
      str1: '', // 기일명
      str2: '', // 결과
      str3: '', // 장소
      txt1: '', // 기일준비사항
      txt2: '', // 의뢰인주장
      txt3: '', // 상대방주장
      txt4: '', // 재판부판단
      parentInfo: '' // [2021.3.23] 부모정보 문자열
    },
    // !!구분: 팝업을 열면서 넘어온 subtype 을 매칭시켜야 한다!
    subType: 1,
    // 구분: 업무유형 - 카테고리
    upmuCates: [],
    // 구분: 기일 - 예정
    subtype1Gubun2: [
      { text: '선택', value: '' },
      { text: '참석', value: '참석' },
      { text: '불참', value: '불참' },
      { text: '복대리', value: '복대리' },
      { text: '변경', value: '변경' }
    ],
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        str1: '기일명'
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: txt1 유효성 체크
    // chkValidate: true, // 사용안함
    // 구분: 일자 - 시간
    date1Selector: false, // 일자 셀렉터
    timeSelector: false, // 시간 셀렉터
    time: '00:00', // 시간 초기값
    noTime: false, // 시간없음 - 기일은 시간이 반드시 존재
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: '',
    // 구분: 자동입력 데이터인 경우
    isAutoInput: false,
    // 구분: 다음기일추가 체크박스
    goNextCourtDay: false,
    // 구분: 자동완성키워드 객체
    keyword: {
      fDate: [] // 기일명 => 중요: str1 이 아니라 fDate
    },
    // 구분:[2021.4.9 추가] 자동완성키워드용 캡션
    kwCaption: {
      fDate: '기일'
    },
    // 구분: 자동완성키워드 검색변수 용도
    sStr1: null, // !! 기일명 찾기
    // 구분: 바로가기에 보여줄 부모 정보
    viewParentInfo: ''
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 1 // 기본팀을 기본값으로
      this.form.subtype = this.subType // 중요: 넘어온 subtype 을 매칭한다!
      this.form.pId = 0 // 부모 아이디
      this.form.pType = 1 // 중요: 부모 타입 = 소송
      this.form.pInfo = '' // 정보위치(부모의 정보 중 보여줄 정보)
      this.form.gubun1 = this.title // !! 넘어온 상단의 제목이 gubun1 이 된다!
      this.form.gubun2 = '' // 기일 - 예정
      this.form.status = 1
      this.form.isStar = 0
      this.form.isSchedule = 1 // 일정표시여부
      this.form.noTime = 0 // [2021.4.23 추가] 시간없음 여부
      this.form.date1 = this.$moment().format('YYYY-MM-DD') // 일시 초기값(오늘)
      this.form.str1 = '' // 기일명
      this.form.str2 = '' // 결과
      this.form.str3 = '' // 장소
      this.form.txt1 = '' // 기일준비사항
      this.form.txt2 = '' // 의뢰인주장
      this.form.txt3 = '' // 상대방주장
      this.form.txt4 = '' // 재판부판단
      this.form.parentInfo = ''

      this.time = '09:00' // 시간 초기값
      this.noTime = false

      this.isAutoInput = false
      this.goNextCourtDay = false // 다음기일추가 초기화

      // 중요: 자동완성키워드도 초기화
      this.keyword.fDate = [] // 기일 주의: str1 이 아님

      this.index = -1 // 수정용 인덱스 초기화
    },
    // 구분: open()
    async open (title, options, subtype, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 중요: 파라미터로 넘어온 subtype 에 따라 기일/기한이 결정되므로 중요하다
        this.subType = subtype

        // console.log(this.article) // !! props 로 넘어온 부모 데이터

        // 초기화
        await this.initDialog()

        // !!중요: - 자동완성 키워드 배열 패칭
        // DB 에서 패칭해 온다.
        this.keyword = await this.getKeywords()

        // !! 아래 3개는 부모로부터 넘겨받은 데이터로 매칭하자(뭐 이렇게 하지 않아도 되는데)
        this.form.teamId = this.article.teamId
        this.form.pId = this.article.id

        // console.log(this.article)
        // 중요:[2021.3.23] 소송정보를 부모정보로 넘기기 위해
        this.form.parentInfo = this.article.caseNum

        // 중요:[2021.3.22] 부모정보의 객체를 문자열로 변환해서 this.form.pInfo 에 담는다.
        this.form.pInfo = JSON.stringify(this.article.pInfo)

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          // console.log(obj) // 파라미터로 넘어온 업무
          this.index = index

          this.form.id = obj.id

          this.form.gubun1 = obj.gubun1 // 진행내용 타입 문자열 - 가일/기한...
          this.form.gubun2 = obj.gubun2 // 기일 - 예정
          this.form.status = obj.status
          this.form.subtype = obj.subtype // !! 이미 초기화에서 적용했으므로 의미는 없는데?
          this.form.isStar = obj.isStar

          // !! 진행내용에선 의미 없지만 일단 놔둔다.
          // 등록과 같은 자동입력 데이터는 유형을 재조정 - 유형재조정은 포기
          if (obj.subtype === 0) {
            this.isAutoInput = true // 자동입력 데이터임을 표시
          }

          this.form.str1 = obj.str1 // 기일명
          this.form.str2 = obj.str2 // 결과
          this.form.str3 = obj.str3 // 장소

          this.form.txt1 = obj.txt1 // 기일준비사항
          this.form.txt2 = obj.txt2 // 의뢰인주장
          this.form.txt3 = obj.txt3 // 상대방주장
          this.form.txt4 = obj.txt4 // 재판부판단

          // 일자-시간 처리
          // 중요:[2021.4.7] this.form.noTime 처리가 추가되었다
          this.form.noTime = obj.noTime
          this.form.date1 = obj.date1.substr(0, 10)
          if (this.form.noTime) { // 시간없음
            this.noTime = true
            this.time = '09:00'
          } else {
            this.noTime = false
            this.time = obj.date1.substr(11, 5)
          }

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 시간없음으로 바꾸는 경우 이벤트 핸들러
    async chNoTime () {
      try {
        this.$nextTick(() => {
          if (this.noTime) {
            this.form.noTime = 1
            this.time = '00:00'
          } else {
            this.form.noTime = 0
            this.time = '09:00'
          }
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록/수정
    async agree () {
      try {
        // 중요: 자동완성 항목에 입력하고 바로 등록 버튼을 누르면 자동완성 항목이 비어있는 것으로 인식한다.
        // 아래처럼 약간의 시간텀을 주어야 제대로 된다.
        sleep(50).then(() => { this.ioProc() })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 자동완성의 즉각 반영 문제로 등록/수정을 실제로 하도록 한번 더 감싼 함수
    async ioProc () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // !! 업무일시 문자열 만들기 - 년월일 + 시간 + 00초
        // 중요:[2021.4.7 수정] 시간이 없는 경우 00:30:01 로 들어가도록 했다!
        if (this.form.noTime) {
          this.form.date1 = `${this.form.date1.substr(0, 10)} 00:30:01`
        } else {
          this.form.date1 = `${this.form.date1.substr(0, 10)} ${this.time}:01`
        }

        // !! 돌려주는 객체
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러가 있는 경우 채워지는 객체
          rsMsg: '' // resolve 메시지
        }

        // !! DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post('lawork/case/setUpmuContent', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // 리턴값으로 resolve 채우기
        resolve.isDeleted = data.isDeleted
        resolve.teamError = data.teamError
        resolve.chInfo = data.chInfo
        resolve.rsMsg = this.goNextCourtDay ? 'goNextCourtDay' : 'edited' // !! 다음기일추가인 경우 goNextCourtDay 로 리턴을 보낸다

        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        // 중요:!![2021.4.9] 키워드 관리 대대적인 수정
        let sendKeyword = [] // 새로운 키워드가 있는 경우 이 배열에 넣고 백앤드로 보내서 입력한다
        let newKeyword = false // 새로 들어간 키워드가 1개라도 있으면 true

        // !! 순회하면서 폼의 필드값이 자동완성 배열에 기존에 없던 넘이면 맨 앞에 넣어준다.
        // 중요:[2021.4.9] 소송/자문의 등록/수정 창에만 당사자유형을 처리하기 위해 아래처럼 해줘야 한다!
        // 소송 등록과 달리 기일명(str1) 1개 밖에 없으므로 순회가 필요없다.
        const field = 'fDate' // 기일
        if (this.form.str1 && !this.keyword[field].includes(this.form.str1)) {
          this.keyword[field].unshift(this.form.str1)
          newKeyword = true
          sendKeyword[field] = this.keyword[field] // 새 항목이 있는 것만 자동으로 걸러진다.
          sendKeyword.push({ field, caption: this.kwCaption[field], keywords: this.keyword[field] })
        }

        // !! 새로운 추가 키워드가 있으면 백앤드로 보내서 입력한다.
        // 기존과 다르게 배열을 넘기는 lawork/lwc/setKeywordArray 로 보낸다.
        if (newKeyword) {
          const { data } = await this.$axios.post('lawork/lwc/setKeywordArray', { sendKeyword })
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        }

        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        // 등록 후
        await this.initDialog()
        this.resolve(resolve) // !! this.resolve(returnResult)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !! 돌려주는 객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post(`lawork/case/deleteUpmuContent`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed' // 삭제됨

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 일자 셀렉트 선택시 저장 함수
    saveDate1 (date) {
      this.$refs.date1Selector.save(date)
    },
    // !! 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      // !! 현재 경로가 case 디렉토리가 아닌 경우만 case/ 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('case') !== -1)) {
        this.$router.push(`/case/${this.article.id}`)
      }
    },
    // 구분: 자동완성 키워드 필터 -
    // itemText 에서 queryText 를 찾는거라 보면됨
    ackFilter (item, queryText, itemText) {
      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.indexOf(query) > -1
    },
    // 구분: 자동완성 키워드 목록 패칭
    async getKeywords () {
      try {
        // !! 현재 페이지의 키워드 요청: this.keyworkd 의 key 배열만 fields 변수에 넣어서 요청
        const { data } = await this.$axios.post('lawork/lwc/getKeywords', { fields: Object.keys(this.keyword) })
        if (!data.success) throw new Error(`자동완성 키워드를 가져오지 못했습니다: ${data.message}`)
        // console.log(data)
        return data.keyword
      } catch (e) {

      }
    }
  }
}
</script>
