<template>
  <v-container fluid class="pa-0 ma-0">
    <v-toolbar dense flat>
      <!-- 주간보기선택 -->
      <div class="hidden-xs-only" style="width: 90px">
        <v-select
          v-model="weekdays"
          :items="weekdaysOptions"
          label=""
          hide-details
          menu-props="auto"
          class="pa-0"
          dense
          style="font-size: 0.785rem !important"
          @change="changeWeekdays"
        ></v-select>
      </div>
      <!-- 달력모드선택: 토클 스위치 아이콘을 활용한 버튼 -->
      <v-btn
        v-model="type"
        text
        small
        class="px-1 ml-2"
        @click="changeType"
      >
        <v-icon
          :color="type === 'month' ? 'deep-orange lighten-1' : 'light-green darken-2'"
        >
          {{ type === 'month' ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
        </v-icon>
        <span>{{ type === 'month' ? '월간' : '일간' }}</span>
      </v-btn>
      <!-- 이전 버튼 -->
      <v-btn
        icon
        small
        class="ml-1"
        color="info darken-1"
        @click="prev"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <!-- 날짜 타이틀 표시 영역: 글자는 computed.title() 에서 제어함. 클릭시 작은 선택 달력을 띄운다 -->
      <v-menu
        ref="dateSelector"
        v-model="dateSelector"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            class="text-h6 mx-1"
            color="info darken-1"
            :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-2 mx-0' : 'text-h6 mx-1'"
            style="cursor: pointer;"
          >
            {{ title }}
          </v-btn>
        </template>
        <v-date-picker
          ref="picker"
          v-model="focus"
          locale="ko"
          :active-picker.sync="activePicker"
          :max="maxYear"
          min="1980-01-01"
          @change="dateSelect(start)"
        ></v-date-picker>
      </v-menu>
      <!-- 다음 버튼 -->
      <v-btn
        icon
        small
        class="mr-2"
        color="info darken-1"
        @click="next"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <!-- 이번달/오늘 버튼 -->
      <v-btn
        v-model="search.today"
        text
        small
        class="px-1"
        @click="btnClick('today')"
      >
        <v-icon small
          :color="search.today ? 'primary' : ''"
        >mdi-calendar</v-icon>
        <span>{{ type === 'month' ? '이번달' : '오늘' }}</span>
      </v-btn>
      <!-- 중요 버튼 -->
      <v-btn
        v-model="search.star"
        text
        small
        class="px-1 mx-1"
        @click="btnClick('star')"
      >
        <v-icon small
          :color="search.star ? 'amber darken-1' : ''"
        >mdi-star</v-icon>
        <span>중요</span>
      </v-btn>
      <!-- 사용안함:[2021.4.7]폐기 [정렬] 업무일/등록일 -->
      <!-- <div class="ml-2 hidden-xs-only" style="width: 150px">
        <v-select
          v-model="sort.default"
          :items="select.defaultSort"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectOrder"
        ></v-select>
      </div> -->
      <!-- 담당부서 -->
      <div class="hidden-xs-only" style="width:150px">
        <v-select
          v-model="search.ss2"
          :items="select.ss2"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mx-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss2')"
        ></v-select>
      </div>

      <v-spacer></v-spacer>

      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen2"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
      <!-- <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-crop</v-icon>
        캡쳐
      </v-btn> -->
      <!-- !! 검색 아이콘 처리 영역 start -->
      <v-menu
        v-model="searchMenu"
        :close-on-content-click="false"
        :nudge-width="50"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text small class="hidden-xs-only">
            <v-icon small>mdi-magnify</v-icon>
            검색
          </v-btn>
        </template>
        <v-card
          max-width="550"
          min-width="550"
          tile
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-toolbar dark color="primary" dense flat
            class="mb-0 pb-0"
          >
            <v-toolbar-items>
              <v-btn
                dark text class="orange--text text-h6 font-weight-bold" @click="searchPopBtn">검색</v-btn>
              <v-btn
                dark text class="white--text subheading" @click="searchMenu = false">취소</v-btn>
              <v-btn text icon
                @click="setInitial"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                    >mdi-refresh</v-icon>
                  </template>
                  <span>초기화</span>
                </v-tooltip>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="mt-0 pt-0">
            <v-row
              align="center"
              justify="center"
              no-gutters
              class="ma-0 pa-0"
            >
              <!-- 검색어 검색 -->
              <v-col cols="12" xs="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <div style="width: 140px;">
                    <v-select
                      v-model="search.sf"
                      :items="select.sf"
                      label=""
                      item-value="value"
                      item-text="text"
                      hide-details
                      class="mt-4 mr-3"
                      dense
                      style="font-size: 0.785rem !important"
                      @change="changeSearchField"
                    ></v-select>
                  </div>
                  <v-text-field
                    v-model="search.sw"
                    label="검색"
                    append-icon="mdi-magnify"
                    maxlength="20"
                    clearble
                    class="mt-5 mr-3"
                    @keyup.enter="searchPopBtn"
                    @click:append="searchPopIcon"
                  ></v-text-field>
                  <v-alert
                    v-show="showUpmuMessage"
                    dense
                    outlined
                    color="error"
                    border="left"
                    class="text-body-2"
                    transition="scale-transition"
                  >
                    업무자 검색시 '업무'만 선택됩니다. 업무外메뉴를 선택하려면 초기화 하십시오.
                  </v-alert>
                </v-row>
              </v-col>
              <!-- 심급 -->
              <!-- <v-col cols="12" xs="12" class="ma-0 px-0 py-1">
                <v-row no-gutters>
                  <v-col cols="12" sm="1" class="ma-0 pa-0 text-center">
                  </v-col>
                  <v-col cols="12" sm="10" class="ma-0 pa-0 text-center">
                    <v-select
                      v-model="search.ss5"
                      :items="select.ss5"
                      label=""
                      item-value="value"
                      item-text="text"
                      hide-details
                      class="mt-3 mr-3"
                      dense
                      style="font-size: 0.785rem !important"
                      @change="selectChange('ss5')"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="1" class="ma-0 pa-0 text-center">
                  </v-col>
                </v-row>
              </v-col> -->
              <!-- 표시항목 처리 -->
              <v-col cols="12" xs="12" class="ma-0 px-0 pt-3 pb-1">
                <v-row no-gutters>
                  <v-col cols="6" class="mt-0">
                    <span class="text-subtitle-2 mt-0 mb-2 pb-0 grey--text">표시항목</span>
                    <v-tooltip
                      slot="tooltip-area"
                      bottom class="ml-3"
                      color="primary"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          small
                          class="mb-1 ml-2"
                          color="primary"
                          style="cursor:pointer;"
                        >mdi-help-circle-outline</v-icon>
                      </template>
                      <span class="text-body-2">적어도 1개의 표시항목을 선택해야 합니다.</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" class="mt-0 text-end">
                    <!-- 사용안함: 설정저장 버튼 -->
                    <!-- <v-chip
                      :outlined="configSave ? false : true"
                      label
                      color="primary"
                      class="mt-0 mb-2 pb-0"
                      @click="configSave = !configSave"
                    >
                      <v-icon small left>
                        {{ configSave ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                      설정저장
                    </v-chip> -->
                    <!-- 사용안함: 전체선택 버튼 -->
                    <v-chip
                      :outlined="choicAll ? false : true"
                      label
                      color="primary"
                      class="mt-0 mb-2 mr-2 pb-0"
                      @click="btnChoiceAll"
                    >
                      <v-icon small left>
                        {{ choicAll ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                      전체선택
                    </v-chip>
                  </v-col>
                  <v-col cols="12" xs="12">
                    <v-row
                      no-gutters
                      align="center"
                      justify="center"
                      class="ma-0 pa-0"
                    >
                      <!-- !! 표시항목들 보여주기 -->
                      <v-col cols="12" class="ma-0 px-5 py-1 text-start">
                        <template
                          v-for="(item, i) in select.vtype"
                        >
                          <v-chip
                            :key="i"
                            small
                            :color="item.clicked ? item.chipColor : 'white'"
                            class="ml-2"
                            @click="checkboxClick('vtype', item)"
                          >
                            <v-icon small left class="mr-2">{{ item.clicked ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                            {{ item.text }}
                          </v-chip>
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="mt-3"></div>
                    <v-alert
                      v-show="showAuthMessage"
                      dense
                      outlined
                      color="error"
                      border="left"
                      class="text-body-2"
                      transition="scale-transition"
                    >
                      관리권한이 없습니다. 관리(대행)자에게 문의하시기 바랍니다.
                    </v-alert>
                    <div class="mb-3"></div>
                  </v-col>
                </v-row>
              </v-col>
              <!--// 표시항목 처리 -->
              <!-- !![2021.7.19] 관리그룹 select 검색 처리 -->
              <v-col cols="12" xs="12" class="mt-3 mx-0 px-0 pt-2 pb-1">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" class="mt-0">
                    <span class="text-subtitle-2 mt-0 mb-2 pb-0 grey--text">관리그룹</span>
                  </v-col>
                  <v-col cols="12"  xs="12"
                    v-for="i in 2"
                    :key="i"
                  >
                    <v-row
                      no-gutters
                      align="center"
                      justify="center"
                      class="mt-2 mb-3 mx-0 pa-0"
                    >
                      <v-col cols="12" sm="3" class="ma-0 pa-0 text-center">
                        <span class="text-body-2 text--primary">{{ mgTitle[`${i}`] }}</span>
                      </v-col>
                      <v-col cols="12" sm="9" class="ma-0 py-0 pr-2">
                        <v-select
                          v-model="search[`mgroup${i}`]"
                          :items="select[`mgroup${i}`]"
                          label=""
                          item-value="value"
                          item-text="text"
                          hide-details
                          class="mt-0 mb-2 mr-3"
                          dense
                          style="font-size: 0.875rem !important"
                          @change="selectChange(`mgroup${i}`)"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-8"></div>
                  </v-col>
                </v-row>
              </v-col>
              <!--// 관리그룹 처리 -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      <!-- 검색 아이콘 처리 영역 end -->
    </v-toolbar>

    <v-toolbar dense flat color="grey lighten-2" class="hidden-xs-only">
      <div class="text-left mr-0">
        <v-btn text icon
          @click="setInitial"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-refresh</v-icon>
            </template>
            <span>초기화</span>
          </v-tooltip>
        </v-btn>
      </div>
      <div class="text-left hidden-xs-only">
        <v-chip
          v-for="(item, i) in searchKeywords"
          :key="i"
          small
          :close="!item.isEver"
          color="grey lighten-4"
          class="mr-1"
          @click:close="closeSearchKeyword(item)"
        >{{ item.text }}</v-chip>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card
      tile
      :elevation="0"
      color="primary"
      class="py-1"
    >
      <v-sheet class="pb-12">
        <v-calendar
          id="myCalendar"
          ref="calendar"
          v-model="focus"
          color="primary"
          :type="type"
          :now="today"
          locale="ko"
          :weekdays="weekdays"
          :show-month-on-first="false"
          :day-format="timestamp => new Date(timestamp.date).getDate()"
          :show-interval-label="intv => intv.minute === 0"
          :first-interval="intervals.first"
          :interval-minutes="intervals.minutes"
          :interval-count="intervals.count"
          :interval-height="intervals.height"
          @change="updateRange"
        >
          <!-- !! day-label 슬롯 : 월간일정의 날짜 표기를 담당한다 -->
          <template v-slot:day-label="label">
            <v-hover v-slot:default="{ hover }">
              <div
                @click.stop="viewDay(label)"
                class="text-caption text-start pl-1"
                :style="hover ? 'text-decoration: underline;' : ''"
              >
                <v-chip v-if="label.present"
                  x-small
                  color="primary"
                  class="px-1"
                  style="cursor: pointer;font-size: 0.75rem;font-weight: 400;"
                >
                  {{ label.day }}
                </v-chip>
                <v-chip v-else
                  x-small
                  :color="hover ? 'yellow lighten-3' : 'white'"
                  class="px-1"
                  style="cursor: pointer;font-size: 0.75rem;font-weight: 400;"
                >
                  {{ label.day }}
                </v-chip>
                <span class="error--text pl-1" style="font-weight: 700;letter-spacing: 0.08em;">{{ holyDay[label.date] }}</span>
              </div>
            </v-hover>
          </template>
          <!-- !! day slot : 월간모드 > 데이터 뿌리기 -->
          <template v-slot:day="{ date }">
            <v-row
              no-gutters
              class="ma-0 pa-0 text-caption d-block"
            >
              <v-card
                tile
                elevation="0"
                min-height="80"
                class="pa-0 text-caption"
                :class="monthPastColor(date)"
              >
                <template v-if="datas[date]">
                  <!-- // ! 해당 일자의 데이터를 뿌리는 영역 -->
                  <div
                    class="px-1 pa-1 text-start"
                    v-for="item in datas[date]"
                    :key="item.id"
                    style="cursor: pointer;"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <!-- 중요: 아래 div에 class="text-truncate" 을 붙이면 말줌임표를 넣을 수 있다! -->
                      <div
                        class="pa-1 mb-0"
                        @click.stop="itemClicked(item)"
                        style="letter-spacing: 0.03333em;line-height: 1.6;"
                        :style="hover ? 'background-color: #F3FBFE;' : ''"
                      >
                        <!-- 중요표시 아이콘 -->
                        <v-icon x-small
                          v-show="item.isStar"
                          color="amber darken-1"
                          class="mb-1 mr-1"
                        >mdi-star</v-icon>
                        <!-- 종국아이콘 -->
                        <v-icon x-small
                          v-show="item.vtype === '218'"
                          color="warning"
                          class="mb-1 mr-1"
                        >mdi-gavel</v-icon>
                        <!-- 구분: 상태칩 영역 -->
                        <!-- > 비용/수금 상태칩 -->
                        <!-- ::[2021.4.1] 비용은 들어냄(231) -->
                        <!-- ::[2021.4.5] 수금은 '수금'으로 통일 item.gubun1 삭제 -->
                        <v-chip
                          v-if="item.vtype === '241'"
                          label outlined x-small class="ml-0 mr-1 my-0 px-1"
                          color="blue-grey darken-2"
                          style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                        >
                          수금
                        </v-chip>
                        <!-- 사용안함:[2021.4.2 폐기] 구매내역/정산내역 상태칩 -->
                        <!-- <v-chip
                          v-else-if="item.vtype === '471' || item.vtype === '481'"
                          label outlined x-small class="ml-0 mr-1 my-0 px-1"
                          color="blue-grey darken-2"
                          style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                        >
                          {{ item.gubun1.substr(0, 2) }}
                        </v-chip> -->
                        <!-- 그외의 경우: 진행내용, 업무 등 상태칩 [2021.3.12] 수정함 -->
                        <!-- !![2021.4.5] 수정 - 진행내역은 칩삭제 -->
                        <v-chip
                          v-else-if="item.vtype === '221'"
                          label outlined x-small class="ml-0 mr-1 my-0 px-1"
                          :color="`${iconColors[item.vtype]}`"
                          style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                        >
                          {{ item.gubun1 }}
                        </v-chip>

                        <!-- @:구분:@: 각 메뉴의 주요내용 영역 1 - 앞대가리 -->
                        <!-- @: 업무 -->
                        <template v-if="item.vtype === '221'">
                          <span class="primary--text text--darken-1">{{ item.str2 ? item.str2 : '' }}</span><!-- 업무자 -->
                          <span>{{ item._place_time_ }}</span><!-- 장소,시간 -->
                          <div v-show="item.str1">{{ cutString(item.str1, 46) }}</div><!-- 내용 -->
                        </template>
                        <!-- @: 기일 -->
                        <template v-else-if="item.vtype === '211'">
                          <span class="font-weight-bold" :class="`${textColors[item.vtype]}`">{{ item.str1 }}</span><!-- 기일명 -->
                          <span class="primary--text" v-html="item._place_time_"></span><!-- (장소 + 시간) -->
                        </template>
                        <!-- @: 기한 -->
                        <template v-else-if="item.vtype === '212'">
                          <span class="font-weight-bold" :class="`${textColors[item.vtype]}`">{{ item.str1 }}</span><!-- 기한명 -->
                        </template>
                        <!-- @: 송달 -->
                        <template v-else-if="item.vtype === '215'">
                          <span class="font-weight-bold" :class="`${textColors[item.vtype]}`">{{ item.str1 }}</span><!-- 제목 -->
                        </template>
                        <!-- @: 종국 -->
                        <template v-else-if="item.vtype === '218'">
                          <span>{{ item.view1 }}</span>
                        </template>
                        <!-- @: 수금 -->
                        <template v-else-if="item.vtype === '241'">
                          <v-chip
                            v-show="item.gubun2 === '미발행'"
                            x-small label class="mx-1 my-0 px-1" color="error"
                          >미발행</v-chip>
                          <span>
                            {{ item.str2 ? `￦${numberFormat(item.str2)}` : '' }}
                            {{ item.rInfo ? ` - ${item.rInfo}` : '' }}
                          </span>
                        </template>
                        <!-- @: 그외: 명령, 제출, 공고, 접수 -->
                        <template v-else>
                          <span class="font-weight-bold" :class="`${textColors[item.vtype]}`">{{ item.str1 }}</span><!-- 제목 -->
                        </template>

                        <!-- @:구분:@: 연계된 부모정보 - 중간에 보임 -->
                        <div class="grey--text text--darken-1" style="font-size: 0.8rem;font-weight: 400;">
                          <template v-if="item.pType === 1"><!-- 소송 -->
                            <div>
                              {{ item.pInfo.courtName }} {{ item.pInfo.caseNum }}{{ item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : '' }}
                            </div>
                            <div>
                              <span class="primary--text text--lighten-1">{{ item._dcl_ }}</span>
                              <span>{{ item._dcl_ && item._dop_ ? `/${item._dop_}` : item._dop_ }}</span>
                            </div>
                          </template>
                          <template v-else-if="item.pType === 2"><!-- 자문 -->
                            <div>
                              {{ item.pInfo.manageNum }}
                            </div>
                            <div>
                              <span class="primary--text">{{ item._dcl_ }}</span>
                              <span>{{ item._dcl_ && item._dop_ ? `/${item._dop_}` : item._dop_ }}</span>
                            </div>
                          </template>
                          <template v-else-if="item.pType === 3"><!-- 인명부 -->
                            <div>
                              <span class="primary--text">{{ item.pInfo.name }}</span>
                            </div>
                          </template>
                          <template v-else><!-- 업무인 경우만: 독립업무 뷰 -->
                            <div v-show="item.vtype === '221'">[독립업무]</div>
                          </template>
                          <!-- {{ item._groups_ ? ` - ${item._groups_}` : '' }}{{ item.teamName ? ` [${item.teamName}]` : ''}} -->
                          <div v-show="item._groups_">
                            {{ `- ${item._groups_}` }}
                          </div>
                          <div v-show="item.teamName">
                            {{ `[${item.teamName}]` }}
                          </div>
                        </div>

                        <!-- @:구분:@: 각 메뉴의 주요내용 영역 2 - 뒷꼭지 -->
                        <!-- @: 업무 -->
                        <template v-if="item.vtype === '221'">
                          <div v-show="item.subtype2 === 0">
                            <span class="ml-1 font-weight-bold primary--text">#{{ item.gubun2 }}</span><!-- 업무관리상태 -->
                          </div>
                        </template>
                        <!-- @: 기일 -->
                        <template v-else-if="item.vtype === '211'">
                          <div v-show="item.str2 || item._chk_end_">
                            <span v-show="item.str2" class="font-weight-bold error--text">{{ item.str2 }}</span><!-- 결과 -->
                            <span v-show="item._chk_end_" class="font-weight-bold error--text">{{ item._chk_end_ }}</span><!-- 종국확인 -->
                          </div>
                          <div v-show="item.gubun2" class="font-weight-bold primary--text"><!-- 예정 -->
                            #{{ item.gubun2 }}
                          </div>
                        </template>
                        <!-- @: 기한 -->
                        <template v-else-if="item.vtype === '212'">
                          <div v-show="item.str2" class="font-weight-bold primary--text">{{ item.str2 }}</div><!-- 대상자 -->
                        </template>
                        <!-- @: [2022.9.20 추가] 제출 결과 -->
                        <template v-else-if="item.vtype === '214'">
                          <div v-show="item.str2" class="font-weight-bold error--text">{{ item.str2 }}</div>
                        </template>
                        <!-- @: 송달 -->
                        <template v-else-if="item.vtype === '215'">
                          <div v-show="item.date2 && item.str2" class="font-weight-bold error--text">{{ strDateFormat(item.date2) }} {{ item.str2 }}</div>
                        </template>
                        <!-- @: 종국 -->
                        <template v-else-if="item.vtype === '218'">
                          <div v-show="item.view2" class="font-weight-bold error--text">{{ item.view2 }}</div>
                        </template>
                        <!-- @: 수금 -->
                        <template v-else-if="item.vtype === '241'">
                          <div v-show="item._sugum_delay_"><!-- 수금지체 -->
                            <span v-show="item._sugum_delay_" class="ml-0 font-weight-bold error--text">{{ item._sugum_delay_ }}</span>
                          </div>
                          <div v-show="item.gubun3" class="ml-1 font-weight-bold primary--text"><!-- 관리상태 -->
                            #{{ item.gubun3 }}
                          </div>
                        </template>

                      </div>
                    </v-hover>
                  </div>
                </template>
              </v-card>
            </v-row>
          </template>
          <!-- !! day-header slot : 주간/일간 모드를 담당한다 -->
          <template v-slot:day-header="{ date, day, weekday, present }">
            <v-card
              tile
              elevation="0"
              min-height="140"
            >
              <!-- !! 라벨 버튼을 새로 만들고 공휴일 적용 -->
              <!-- <v-hover v-slot:default="{ hover }">
                <div class="text-center pa-3">
                  <v-btn
                    v-show="hover"
                    icon
                    small
                    class="mx-1"
                    @click="prev"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-avatar
                    :color="present ? 'primary' : 'yellow lighten-3'"
                    size="42"
                    @click.stop="changeType"
                    style="cursor:pointer;"
                  >
                    <v-icon v-if="hover"
                      color="warning"
                    >mdi-calendar-month</v-icon>
                    <span v-else
                      class="text-h6"
                      :class="!present ? setDayColor({ date, weekday }, 'class') : 'white--text'"
                    >{{ day }}</span>
                  </v-avatar>
                  <span class="text-caption error--text pl-1">{{ holyDay[date] }}</span>
                  <v-btn
                    v-show="hover"
                    icon
                    small
                    class="mx-1"
                    @click="next"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-hover> -->
              <v-hover v-slot:default="{ hover }">
                <div class="text-center pa-3">
                  <v-avatar
                    size="42"
                    :color="present ? 'primary' : 'grey lighten-3'"
                    :dark="present ? true : false"
                    class="text-h6"
                  >
                    <span
                      :class="!present ? setDayColor({ date, weekday }, 'class') : 'white--text'"
                    >{{ day }}</span>
                  </v-avatar>
                  <span class="error--text pl-1" style="font-size: 0.875rem;font-weight: 700;letter-spacing: 0.08em;">{{ holyDay[date] }}</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-show="hover"
                        v-on="on"
                        icon
                        class="mx-1"
                        :color="present ? 'primary' : setDayColor({ date, weekday })"
                        @click="prev"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                    </template>
                    <span>이전</span>
                  </v-tooltip>
                  <v-expand-x-transition>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-show="hover"
                          v-on="on"
                          icon
                          @click.stop="changeType"
                        >
                          <v-icon :color="present ? 'primary' : setDayColor({ date, weekday })">
                            mdi-calendar-month
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>월간모드</span>
                    </v-tooltip>
                  </v-expand-x-transition>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-show="hover"
                        v-on="on"
                        icon
                        class="mx-1"
                        :color="present ? 'primary' : setDayColor({ date, weekday })"
                        @click="next"
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <span>다음</span>
                  </v-tooltip>
                </div>
              </v-hover>
              <v-divider></v-divider>
              <!-- // ! 순회하면서 해당 날짜 데이터를 뿌린다 -->
              <template v-if="datas[date]">
                <template v-for="item in datas[date]">
                  <!-- !! 일간 -->
                  <div
                    :key="item.id"
                    class="text-body-2 my-1 px-2 py-1"
                  >
                    <div
                      class="py-1"
                      style="cursor: pointer;"
                      @click.stop="itemClicked(item)"
                    >
                      <!-- 중요표시 아이콘 -->
                      <v-icon small
                        v-if="item.isStar"
                        color="amber darken-1"
                        class="mr-1 pb-1"
                      >mdi-star</v-icon>
                      <!-- 종국 아이콘 -->
                      <v-icon
                        v-show="item.vtype === '218'"
                        small
                        color="warning"
                        class="mr-1 pb-1"
                      >mdi-gavel</v-icon>
                      <!-- <strong>{{ strDateFormat3(item.date1) }}</strong> -->
                      <!-- 구분: 상태칩 영역 -->
                      <!-- 비용/수금 상태칩 -->
                      <!-- ::[2021.4.1] 비용은 들어냄(231) -->
                      <!-- ::[2021.4.5] 수금은 '수금'으로 통일 item.gubun1 삭제 -->
                      <v-chip
                        v-if="item.vtype === '241'"
                        label outlined x-small class="mx-1 my-0 px-1"
                        color="blue-grey darken-2"
                        style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                      >
                        수금
                      </v-chip>
                      <!-- 사용안함:[2021.4.2 폐기] 구매내역/정산내역 상태칩 -->
                      <!-- <v-chip
                        v-else-if="item.vtype === '471' || item.vtype === '481'"
                        label outlined x-small class="mx-1 my-0 px-1"
                        color="blue-grey darken-2"
                        style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                      >
                        {{ item.gubun1.substr(0, 2) }}
                      </v-chip> -->
                      <!-- 그외의 경우: 진행내용, 업무 등 상태칩 [2021.3.12] 수정함 -->
                      <!-- !![2021.4.5] 수정 - 진행내역은 칩삭제 -->
                      <!-- 사용안함:[2021.4.5 폐기] 다시 수정 - 업무의 칩은 아래 업무 앞대가리로 이동시킴 -->
                      <!-- <v-chip
                        v-else-if="item.vtype === '221'"
                        label outlined x-small class="mx-1 my-0 px-1"
                        :color="`${iconColors[item.vtype]}`"
                        style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                      >
                        {{ item.gubun1 }}
                      </v-chip> -->

                      <!-- @:구분:@: 각 메뉴의 주요내용 영역 1 - 앞대가리 -->
                      <!-- @: 업무 -->
                      <template v-if="item.vtype === '221'">
                        <span class="font-weight-bold purple--text">{{ item.tm && !item.noTime ? `${item.tm} ` : '' }}</span><!-- 시간 -->
                        <!-- 업무의 타이틀 칩: 시간 뒤로 이동시킴 -->
                        <v-chip
                          label outlined x-small class="mx-1 my-0 px-1"
                          :color="`${iconColors[item.vtype]}`"
                          style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                        >
                          {{ item.gubun1 }}
                        </v-chip>
                        <span v-show="item.str2" class="mr-1 primary--text text--darken-1">{{ item.str2 }}</span><!-- 업무자 -->
                        <span v-show="item._place_time_" class="mr-1">{{ item._place_time_ }}</span><!-- 장소,시간 -->
                        <span v-show="item.str1" class="mr-1 grey--text text--darken-3"> {{ cutString(item.str1, 60) }}</span><!-- 내용 -->
                      </template>
                      <!-- @: 기일 -->
                      <template v-else-if="item.vtype === '211'">
                        <span class="font-weight-bold purple--text">{{ item.tm && !item.noTime ? `${item.tm} ` : '' }}</span><!-- 시간 -->
                        <span class="mr-1 font-weight-bold primary--text">{{ item.str1 }}</span><!-- 기일명 -->
                        <span class="mr-1 primary--text" v-html="item._place_time_"></span><!-- (장소 + 시간) -->
                      </template>
                      <!-- @: 기한 -->
                      <template v-else-if="item.vtype === '212'">
                        <span class="mr-1 font-weight-bold red--text text--darken-1">{{ item.str1 }}</span><!-- 기한명 -->
                      </template>
                      <!-- @: 송달 -->
                      <template v-else-if="item.vtype === '215'">
                        <span class="mr-1 font-weight-bold" :class="`${textColors[item.vtype]}`">{{ item.str1 }}</span><!-- 제목 -->
                      </template>
                      <!-- @: 종국 -->
                      <template v-else-if="item.vtype === '218'">
                        <span class="mr-1">{{ item.str1 }}</span>
                        <span class="mr-1 grey--text text--darken-3">{{ item.str2 ? ` - ${item.str2}` : '' }}</span>
                      </template>
                      <!-- @: 수금 -->
                      <template v-else-if="item.vtype === '241'">
                        <v-chip
                          v-show="item.gubun2 === '미발행'"
                          x-small label class="mx-1 my-0 px-1" color="error"
                        >미발행</v-chip>
                        <span class="mr-1">
                          {{ item.str2 ? `￦${numberFormat(item.str2)}` : '' }}{{ item.rInfo ? ` (${item.rInfo}) ` : '' }}
                        </span>
                        <span class="mr-1 grey--text text--darken-3" v-show="item.str1"> - {{ cutString(item.str1, 60) }}</span><!-- 내용 -->
                      </template>
                      <!-- @: 그외: 명령, 제출, 공고, 접수 -->
                      <template v-else>
                        <span class="mr-1 font-weight-bold" :class="`${textColors[item.vtype]}`">{{ item.str1 }}</span><!-- 제목 -->
                      </template>

                      <!-- @:구분:@: 각 메뉴의 주요내용 영역 2 - 뒷꼭지 -->
                      <!-- @: 업무 -->
                      <template v-if="item.vtype === '221'">
                        <span v-show="item.subtype2 === 0" class="mr-1 font-weight-bold primary--text">#{{ item.gubun2 }}</span><!-- 업무관리상태 -->
                      </template>
                      <!-- @: 기일 -->
                      <template v-else-if="item.vtype === '211'">
                        <span v-show="item.str2" class="font-weight-bold error--text">{{ item.str2 }}</span><!-- 결과 -->
                        <span v-show="item._chk_end_" class="font-weight-bold error--text">{{ item._chk_end_ }}</span><!-- 종국확인 -->
                        <span v-show="item.gubun2" class="font-weight-bold primary--text" :class="item.str2 || item._chk_end_ ? 'ml-1' : ''">#{{ item.gubun2 }}</span><!-- 예정 -->
                      </template>
                      <!-- @: 기한 -->
                      <template v-else-if="item.vtype === '212'">
                        <span v-show="item.str2" class="font-weight-bold primary--text">({{ item.str2 }})</span><!-- 대상자 -->
                      </template>
                      <!-- @: [2022.9.20 추가] 제출 결과 추가 -->
                      <template v-else-if="item.vtype === '214'">
                        <span v-show="item.str2" class="font-weight-bold error--text">{{ item.str2 }}</span>
                      </template>
                      <!-- @: 송달 -->
                      <template v-else-if="item.vtype === '215'">
                        <span v-show="item.date2 && item.str2" class="font-weight-bold error--text">{{ strDateFormat(item.date2) }} {{ item.str2 }}</span>
                      </template>
                      <!-- @: 종국 -->
                      <template v-else-if="item.vtype === '218'">
                        <span v-show="item.view2" class="font-weight-bold error--text">{{ item.view2 }}</span>
                      </template>
                      <!-- @: 수금 -->
                      <template v-else-if="item.vtype === '241'">
                        <span v-show="item._sugum_delay_" class="ml-0 font-weight-bold error--text">{{ item._sugum_delay_ }}</span><!-- 수금지체 -->
                        <span v-show="item.gubun3" class="ml-1 font-weight-bold primary--text">#{{ item.gubun3 }}</span><!-- 관리상태 -->
                      </template>
                    </div>

                    <!-- @:구분:@: 연계된 부모정보  -->
                    <div
                      class="grey--text text--darken-1"
                      style="font-size: 0.8rem;font-weight: 400;"
                    >
                      <template v-if="item.pType === 1"><!-- 소송 -->
                        <span class="mr-1">{{ item.pInfo.courtName }} {{ item.pInfo.caseNum }}{{ item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : '' }}</span>
                        <span class="mr-1 primary--text text--lighten-1">
                          {{ item._dcl_ }}<span class="grey--text text--darken-1">{{ item._dcl_ && item._dop_ ? `/${item._dop_}` : item._dop_ }}</span>
                        </span>
                      </template>
                      <template v-else-if="item.pType === 2"><!-- 자문 -->
                        <span class="mr-1">{{ item.pInfo.manageNum }}</span>
                        <span class="mr-1 primary--text text--lighten-1">
                          {{ item._dcl_ }}<span class="grey--text text--darken-1">{{ item._dcl_ && item._dop_ ? `/${item._dop_}` : item._dop_ }}</span>
                        </span>
                      </template>
                      <template v-else-if="item.pType === 3"><!-- 인명부 -->
                        <span class="mr-1 primary--text">{{ item.pInfo.name }}</span>
                      </template>
                      <template v-else><!-- 업무인 경우만: 독립업무 뷰 -->
                        <span v-show="item.vtype === '221'" class="mr-1">[독립업무]</span>
                      </template>
                      <v-icon
                        v-show="item.pType"
                        x-small
                        :color="mdiPlusBoxOutlineButtonColor"
                        class="mr-0"
                        @click="parentDirectGo(item)"
                      >mdi-launch</v-icon>
                      {{ item._groups_ ? ` - ${item._groups_}` : '' }}
                      {{ item.teamName ? ` [${item.teamName}]` : ''}}
                      {{ strDateFormat2(item.createdAt) }}
                      {{ item.wname }}
                    </div>

                    <!-- !! v-divider 로 라인 긋기, 사용하려면 v-for에 i를 살려야 한다 -->
                    <!-- <v-divider
                      v-show="(i + 1) < datas[date].length"
                      class="mt-1"
                    ></v-divider> -->

                  </div>
                  <!-- !! 주간: 사용안함: 주석처리 -->
                  <!-- <div
                    v-if="type === 'week'"
                    :key="i"
                    class="text-caption pa-1"
                  >
                    <v-chip
                      x-small
                      color="grey lighten-3"
                      class="px-1"
                    >
                      {{ item.time }}
                    </v-chip>
                    {{ item.title }}
                  </div> -->
                </template>
              </template>
            </v-card>
          </template>
          <!-- interval slot : 주간/일간 모드의 시간별 처리 (사용안함) -->
        </v-calendar>
      </v-sheet>
    </v-card>

    <!-- !! overlay -->
    <v-overlay :value="overlay" opacity="0.25">
      <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
    </v-overlay>
    <!-- !! 소송/자문 업무 팝업 -->
    <rel-content-dialog01 ref="relContentDialog01" :article="article"></rel-content-dialog01>
    <!-- !! 인명부 업무 팝업 1 -->
    <rel-content-dialog02 ref="relContentDialog02" :article="article"></rel-content-dialog02>
    <!-- !! 인명부 업무 팝업 2: [2021.6.25] 고문계약자동갱신 -->
    <rel-content-dialog03 ref="relContentDialog03" :article="article"></rel-content-dialog03>
    <!-- !! 독립메뉴 업무 등록 다이얼로그 참 -->
    <upmu-add-dialog ref="upmuAddDialog"></upmu-add-dialog>
    <!-- 사용안함:[2021.4.2 폐기] 소송/자문 비용 팝업 -->
    <!-- <rel-cost-dialog01 ref="relCostDialog01" :article="article"></rel-cost-dialog01> -->
    <!-- 사용안함:[2021.4.2 폐기] 인명부 비용 팝업 -->
    <!-- <rel-cost-dialog02 ref="relCostDialog02" :article="article"></rel-cost-dialog02> -->
    <!-- !! 소송/자문 수금 팝업 -->
    <rel-sugum-dialog01 ref="relSugumDialog01" :article="article"></rel-sugum-dialog01>
    <!-- !! 인명부 수금 팝업 -->
    <rel-sugum-dialog02 ref="relSugumDialog02" :article="article"></rel-sugum-dialog02>
    <!-- !! 소송: 진행내용 > 기일 등록/수정 팝업 -->
    <rel-jinheng-dialog11 ref="relJinhengDialog11" :article="article"></rel-jinheng-dialog11>
    <!-- !! 소송: 진행내용 > 기한 등록/수정 팝업 -->
    <rel-jinheng-dialog12 ref="relJinhengDialog12" :article="article"></rel-jinheng-dialog12>
    <!-- !! 소송: 진행내용 > 송달 등록/수정 팝업 -->
    <rel-jinheng-dialog13 ref="relJinhengDialog13" :article="article"></rel-jinheng-dialog13>
    <!-- !! 소송: 진행내용 > 종국 등록/수정 팝업 -->
    <rel-jinheng-dialog14 ref="relJinhengDialog14" :article="article"></rel-jinheng-dialog14>
    <!-- !! 소송: 진행내용 > 명령,제출,공고,접수 등록/수정 팝업 -->
    <rel-jinheng-dialog15 ref="relJinhengDialog15" :article="article"></rel-jinheng-dialog15>
    <!-- !! 자문: 진행내용 > 마감기한 등록/수정 팝업 -->
    <rel-jinheng-dialog22 ref="relJinhengDialog22" :article="article"></rel-jinheng-dialog22>
    <!-- !! 자문: 진행내용 > 종국 등록/수정 팝업 -->
    <rel-jinheng-dialog24 ref="relJinhengDialog24" :article="article"></rel-jinheng-dialog24>
    <!-- !! 자문: 진행내용 > 자문요청(접수) 등록/수정 팝업 -->
    <rel-jinheng-dialog25 ref="relJinhengDialog25" :article="article"></rel-jinheng-dialog25>
    <!-- 사용안함:[2021.4.2 폐기] 구매내역 등록/수정 팝업 -->
    <!-- <purchase-dialog ref="purchaseDialog" :article="article"></purchase-dialog> -->
    <!-- 사용안함:[2021.4.2 폐기] 정산내역 등록/수정 팝업 -->
    <!-- <settle-dialog ref="settleDialog" :article="article"></settle-dialog> -->

    <!-- !! 취소없는 확인 다이얼로그 -->
    <confirm-dialog-no-cancel ref="ConfirmDialogNoCancel"></confirm-dialog-no-cancel>

  </v-container>
</template>

<script>
// @: 공휴일 저장 파일
import holyday from '@/lib/holyday'
// @: localStorage
import storage from '@/lib/storage'
// @: sleep
import sleep from '@/lib/sleep'

// @: filters
import cutString from '@/filters/cutString'
import numberFormat from '@/filters/numberFormat'
import strDateFormat from '@/filters/strDateFormat'
import strDateFormat2 from '@/filters/strDateFormat2'
import strDateFormat3 from '@/filters/strDateFormat3'
// import buyStat1 from '@/filters/buyStat1' // 구매내역 > 구매상태
// import buyStat2 from '@/filters/buyStat2' // 구매내역 > 거래형태
// import settleStat1 from '@/filters/settleStat1' // 정산내역 > 정산상태
// import settleStat2 from '@/filters/settleStat2' // 정산내역 > 영수증 발행 상태

// @: dialogs
import relContentDialog01 from '@/components/suit/relContentDialog' // 소송/자문 업무 팝업
import relContentDialog02 from '@/components/client/relContentDialog' // 인명부 업무 팝업 1
import relContentDialog03 from '@/components/client/arContentDialog' // 인명부 업무 팝업 2 - 고문계약자동갱신팝업
import upmuAddDialog from '@/components/UpmuAddDialog' // 독립메뉴 업무 팝업
// import relCostDialog01 from '@/components/suit/relCostDialog' // 소송/자문 비용 팝업 사용안함:[2021.4.2 폐기]
// import relCostDialog02 from '@/components/client/relCostDialog' // 인명부 비용 팝업 사용안함:[2021.4.2 폐기]
import relSugumDialog01 from '@/components/suit/relSugumDialog' // 소송/자문 수금 팝업
import relSugumDialog02 from '@/components/client/relSugumDialog' // 인명부 수금 팝업
// @: 소송
import relJinhengDialog11 from '@/components/suit/relJinhengDialog' // 소송: 진행내용 > 기일 등록/수정 팝업
import relJinhengDialog12 from '@/components/suit/relJinhengDialog2' // 소송: 진행내용 > 기한 등록/수정 팝업
import relJinhengDialog13 from '@/components/suit/relJinhengDialog3' // 소송: 진행내용 > 송달 등록/수정 팝업
import relJinhengDialog14 from '@/components/suit/relJinhengDialog4' // 소송: 진행내용 > 종국 등록/수정 팝업
import relJinhengDialog15 from '@/components/suit/relJinhengDialog5' // 소송: 진행내용 > 명령,제출,공고,접수 등록/수정 팝업
// @: 자문
import relJinhengDialog22 from '@/components/advice/relJinhengDialog2' // 자문: 진행내용 > 마감기한 등록/수정 팝업 주의: advice 에 존재
import relJinhengDialog24 from '@/components/advice/relJinhengDialog4' // 자문: 진행내용 > 종국 등록/수정 팝업 주의: advice 에 존재
import relJinhengDialog25 from '@/components/advice/relJinhengDialog5' // 자문: 진행내용 > 자문요청(접수) 등록/수정 팝업 주의: advice 에 존재
// @: 물품
// import purchaseDialog from '@/components/supply/purchaseDialog' // !! 구매내역 팝업 사용안함:[2021.4.2 폐기]
// import settleDialog from '@/components/supply/settleDialog' // !! 정산내역 팝업 사용안함:[2021.4.2 폐기]

// @: 취소없는 확인 팝업
import ConfirmDialogNoCancel from '@/components/ConfirmDialogNoCancel'

// @: pdfmake list
import { pdfMake, pdfListStyle } from '@/lib/pdfmakeList'
import html2canvas from 'html2canvas'

// @: pdfmake view
import {
  // pdfMake, // 이미 위에서 선언했으니 삭제
  pdfViewStyle,
  // rmTagReturnArray,
  viewType3 } from '@/lib/pdfmakeView'

// !![2021.4.30]lwc vuex 모듈 불러오기
import { createNamespacedHelpers } from 'vuex'

// !! lwc vuex 모듈
const _Lwc_ = createNamespacedHelpers('lwc')

// 주간 요일 기본값: 월 ~ 금
const weekdaysDefault = [1, 2, 3, 4, 5]

// 주간 요일 전체값: 일 ~ 토
const weekdaysAll = [0, 1, 2, 3, 4, 5, 6]

// 일간에서의 시간 인터벌 정의 객체
const intervalOptions = {
  first: 6,
  minutes: 60,
  count: 0,
  height: 40
}

export default {
  components: {
    relContentDialog01,
    relContentDialog02,
    relContentDialog03,
    upmuAddDialog,
    // relCostDialog01,
    // relCostDialog02,
    relSugumDialog01,
    relSugumDialog02,
    relJinhengDialog11,
    relJinhengDialog12,
    relJinhengDialog13,
    relJinhengDialog14,
    relJinhengDialog15,
    relJinhengDialog22,
    relJinhengDialog24,
    relJinhengDialog25,
    // purchaseDialog,
    // settleDialog
    ConfirmDialogNoCancel // 취소없는 확인 팝업
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    mdiListEditButtonColor: 'indigo accent-2',
    mdiTrashCanOutlineButtonColor: 'warning darken-1',
    mdiUploadButtonColor: 'primary lighten-1',
    mdiDownloadButtonColor: 'success lighten-1',
    mdiLinkShareIconColor: 'cyan darken-2',
    mdiOpenFileButtonColor: 'orange lighten-1',
    // 구분: 넘어온 데이터를 패칭할 변수 - !! 리스트와 다르게 배열이 아니라 객체다
    datas: {}, // 일정 데이터
    // 구분: 공휴일 객체 - import 한 holyday 객체를 이변수에 매칭해야 한다!
    holyDay: {},
    // 구분: 달력 관련 변수들
    type: 'month',
    typeOptions: [
      { text: '월간', value: 'month' },
      // { text: '주간', value: 'week' }, // !! [2020.5.21]주간보기 삭제
      { text: '일간', value: 'day' }
    ],
    today: '',
    focus: '',
    start: null,
    end: null,
    startDay: null, // data 패칭의 시작일
    endDay: null, // data 패칭의 마지막일
    dateSelector: false, // 날짜 셀렉트 메뉴 -- 연월(일) 타이틀을 클릭시 작은 달력 메뉴가 나오게 하는 결정인자
    activePicker: null, // 중요:날짜 셀렉터 date picker 의 타입결정인자
    weekdays: weekdaysDefault,
    weekdaysOptions: [
      { text: '월 ~ 금', value: weekdaysDefault },
      { text: '일 ~ 토', value: weekdaysAll }
    ],
    intervals: intervalOptions, // 주석처리
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체(검색용)
    params: { // 검색용 인자 객체
      draw: 0,
      where: {
        type: 'month',
        startDay: '',
        endDay: '',
        focus: '',
        today: true, // 오늘 검색 버튼 초기값
        vtype: [] // 표시항목 설정값 - 초기에는 비어있다.
      },
      sort: [ 'date1' ], // 업무일순 정렬 주의: 정렬의 갯수만큼 초기값 지정해야 함
      order: [ 'ASC' ] // !! 달력에선 내림차순이 아니다!
    },
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    search: {
      sf: 1, // 검색어 검색 select 의 선택된 필드값
      sw: '', // 검색어 검색 text box input 값
      mgroup1: '', // 관리그룹1 검색
      mgroup2: '', // 관리그룹2 검색
      // done: false, // 종국 검색 버튼 클릭값 - true/false
      // ss1: 1, // [관리상태] 셀렉트 검색 선택값
      ss2: '', // [담당부서] 셀렉트 검색 선택값
      // ss3: '', // [소송분야>상위] 셀렉트 검색 선택값
      // ss4: '', // [소송분야>하위] 셀렉트 검색 선택값
      // ss5: '', // [심급] 셀렉트 검색 선택값
      // ss6: '' // [진행방식] 셀렉트 검색 선택값
      today: true, // 오늘 검색 버튼 클릭값 - true/false
      star: false, // 중요 검색 버튼 클릭값 - true/false
      // 구분: 표시항목 - type 에 따른 분류
      vtype: [ '211', '212', '221' ] // 초기값은 기일,기한,업무
    },
    // 구분: 정렬에 필요한 변수들 - 초기화 필요
    sort: {
      default: 1 // [업무일순]기본정렬 매칭 - 정렬의 기본값은 select.sort.value 로 결정
    },
    // 구분: 셀렉트 객체들
    select: {
      sf: [ // 검색어 검색 필드 셀렉트
        { text: '내용', value: 1 },
        { text: '등록자', value: 2 },
        { text: '업무자', value: 3 }
        // { text: '사건당사자(사건기준)', value: 2 },
        // { text: '본인(인명부기준)', value: 3 },
        // { text: '사건명(사건기준)', value: 4 },
        // { text: '관할(소송)', value: 5 },
        // { text: '재판(담당)부(소송)', value: 6 }
      ],
      // ss1: [ // 관리상태 셀렉트  - 나머지는 카테고리에서 불러온다
      //   { text: '관리상태', value: '' },
      //   { text: '진행', value: 1 },
      //   { text: '종결', value: 2 },
      //   { text: '상담', value: 3 },
      //   { text: '보류', value: 4 }
      // ],
      // ss3: [ // 소송분야 > 상위 셀렉트 - 나머지는 카테고리에서 불러온다
      //   { text: '소송분야', value: '' }
      // ],
      // ss4: [ // 소송분야 > 하위 셀렉트 - 나머지는 카테고리에서 불러온다
      //   { text: '상세분야', value: '' }
      // ],
      // ss5: [ // 심급
      //   { text: '심급', value: '' },
      //   { text: '1심', value: '1심' },
      //   { text: '2심', value: '2심' },
      //   { text: '3심', value: '3심' }
      // ],
      // ss6: [ // 진행방식
      //   { text: '진행방식', value: '' },
      //   { text: '일반', value: '일반' },
      //   { text: '전자', value: '전자' }
      // ],
      mgroup1: [ // 관리그룹 1
        { text: '선택', value: '' }
      ],
      mgroup2: [ // 관리그룹 2
        { text: '선택', value: '' }
      ],
      // 구분:
      ss2: [ // 담당부서 셀렉트  - 나머지는 카테고리에서 불러온다
        { text: '담당부서', value: '' }
      ],
      // 사용안함:[2021.4.7 폐기]
      // defaultSort: [ // 기본 정렬 셀렉트 - order 가 매칭되어있다.
      //   { text: '업무일순', value: 1, field: 'date1', order: 'ASC' }, // !! 달력에선 내림차순이 아니다!
      //   { text: '유형순', value: 2, field: 'type', order: 'ASC' },
      //   { text: '등록일순', value: 3, field: 'createdAt', order: 'ASC' }
      // ],
      // 구분: 표시항목 - 일반적인 select 변수가 아니다(value 가 없음)
      // !!주의: searchPopBtn() 함수에서 인덱스를 이용하므로
      //      순서변경을 하는 경우 searchPopBtn() 함수도 수정할 것
      vtype: [
        { text: '기일', value: '211', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '기한', value: '212', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '명령', value: '213', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '제출', value: '214', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '송달', value: '215', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '공고', value: '216', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '접수', value: '217', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '종국', value: '218', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '내역', value: '219', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '업무', value: '221', clicked: false, chipColor: 'light-blue lighten-5' },
        { text: '수금', value: '241', clicked: false, chipColor: 'light-blue lighten-5' }
        // { text: '비용', value: '231', clicked: false, chipColor: 'light-blue lighten-5' }, 사용안함:[2021.4.2 폐기]
        // { text: '물품구매', value: '471', clicked: false, chipColor: 'light-blue lighten-5' }, 사용안함:[2021.4.2 폐기]
        // { text: '구매정산', value: '481', clicked: false, chipColor: 'light-blue lighten-5' } 사용안함:[2021.4.2 폐기]
      ]
    },
    // 구분: 리스트에서 사용할 아이콘 색상
    iconColors: {
      '211': 'primary', // 기일
      '212': 'red darken-1', // 기한
      '213': 'green darken-2', // 명령
      '214': 'brown darken-1', // 제출
      '215': 'orange darken-3', // 송달
      '216': 'blue-grey darken-2', // 공고
      '217': 'blue-grey darken-2', // 접수
      '218': 'blue-grey darken-2', // 종국
      '219': 'blue-grey darken-2', // 내역
      '221': 'blue-grey darken-2', // 업무
      '241': 'blue-grey darken-2' // 수금
      // '231': 'blue-grey darken-2', // 비용 사용안함:[2021.4.2 폐기]
      // '471': 'blue-grey darken-2', // 물품구매내역 사용안함:[2021.4.2 폐기]
      // '481': 'blue-grey darken-2' // 물품정산내역 사용안함:[2021.4.2 폐기]
    },
    // 구분: 리스트에서 사용할 텍스트 색상
    textColors: {
      '211': 'primary--text', // 기일
      '212': 'red--text text--darken-1', // 기한
      '213': 'green--text text--darken-2', // 명령
      '214': 'brown--text text--darken-1', // 제출
      '215': 'orange--text text--darken-3', // 송달
      '216': 'blue-grey--text text--darken-2', // 공고
      '217': 'blue-grey--text text--darken-2', // 접수
      '218': 'blue-grey--text text--darken-2', // 종국
      '219': 'blue-grey--text text--darken-2', // 내역
      '221': 'blue-grey--text text--darken-2', // 업무
      '241': 'blue-grey--text text--darken-2' // 수금
      // '231': 'blue-grey--text text--darken-2', // 비용 사용안함:[2021.4.2 폐기]
      // '471': 'blue-grey--text text--darken-2', // 물품구매내역 사용안함:[2021.4.2 폐기]
      // '481': 'blue-grey--text text--darken-2' // 물품정산내역 사용안함:[2021.4.2 폐기]
    },
    // 구분: 검색어 칩을 사용하는 것들의 배열 - 검색어 칩을 사용하는 요소를 명확히 알 수있다.
    // 타입: 정렬/단일검색/다중검색(배열) - sort/search/arrsearch
    // 이름: 요소이름 - sort.defalut
    // isArr: 체크박스 처럼 값이 배열로 넘어가는 경우
    // select: 비어있지 않은 경우 해당 셀렉트를 이용한다.
    // showSelect: 검색어 검색처럼 셀렉트를 직접 사용하진 않지만 텍스트를 보여줄 경우 사용(select: '' 인 경우에 주로 사용)
    // isEver: true 인 경우 항시 보여야 한다.
    // loading: true 인 경우 로딩시 처리된다
    useSearchKeywords: [
      // 사용안함:[2021.4.7 폐기] { type: 'sort', name: 'default', isArr: false, select: 'defaultSort', showSelect: '', isEver: true, loading: true },
      // { type: 'search', name: 'ss1', select: 'ss1', showSelect: '', isEver: false, loading: true },
      { type: 'search', name: 'ss2', isArr: false, select: 'ss2', showSelect: '', isEver: false, loading: false },
      // { type: 'search', name: 'ss3', isArr: false, select: 'ss3', showSelect: '', isEver: false, loading: false },
      // { type: 'search', name: 'ss4', isArr: false, select: 'ss4', showSelect: '', isEver: false, loading: false },
      // { type: 'search', name: 'ss5', isArr: false, select: 'ss5', showSelect: '', isEver: false, loading: false },
      // { type: 'search', name: 'ss6', isArr: false, select: 'ss6', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'mgroup1', isArr: false, select: 'mgroup1', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'mgroup2', isArr: false, select: 'mgroup2', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'sw', isArr: false, select: '', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'vtype', isArr: true, select: 'vtype', showSelect: '', isEver: true, loading: true }
    ],
    // 구분: 검색 팝업을 위한 변수
    searchMenu: false,
    // 구분: 검색어 칩 배열을 위한 변수
    searchKeywords: [],
    // 구분: 오버레이용 변수
    overlay: false,
    // 구분: 기타 변수들
    timeout: null, // delay() 에서 사용하는 변수 - 초기화 불필요
    // 구분: 설정저장 여부
    configSave: false,
    // 구분: 전체선택 버튼
    choicAll: false,
    // 구분: 소송/자문, 인명부 업무등의 팝업을 위한 변수
    article: {},
    // 구분: 관리그룹의 제목. 카테고리에서 패칭해서 채운다. 관리그룹이 늘어나면 더 늘려야 한다.
    mgTitle: {
      1: '',
      2: ''
    },
    // 구분: 검색 팝업창의 메시지
    showUpmuMessage: false, // 업무자 메시지 - 검색어 셀렉트 선택이 '업무자'인 경우 true
    showAuthMessage: false, // 비용, 수금, 물품관리권한 메시지
    // 구분: 한글 요일(일간일정에서 쓰인다)
    yoilHan: ['일', '월', '화', '수', '목', '금', '토'],
    // 구분: pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      // pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 10, 20, 10, 20 ],
      content: []
    }
  }),

  watch: {
    dateSelector (val) { // 년월일 타이틀의 초기값 또는 변화를 감지하는 부분.
      // !! datepicker 초기모드를 년선택으로 한다. 기본은 이번달이다
      // val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      // 월선택으로 바꾸자
      // val && setTimeout(() => (this.$refs.picker.activePicker = 'MONTH'))
      if (this.type === 'month') {
        // !! 월선택인 경우 월선택 달력이 뜨도록 설정 변경. 기본은 일선택임
        // val && setTimeout(() => (this.$refs.picker.activePicker = 'MONTH')) // 에러! 아래 코드로 대체
        val && setTimeout(() => (this.activePicker = 'MONTH'))
      }
    }
  },

  computed: {
    maxYear () {
      return this.$moment().add(3, 'years').format('YYYY-MM-DD')
    },
    // !! 툴바 타이틀
    title () {
      // 시작일과 마지막일 -> @change="updateRange" 에 의해 지정됨
      const { start, end } = this
      if (!start || !end) return ''

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : `${endYear}년 `

      const startMonth = start.month
      const endMonth = end.month
      const suffixMonth = startMonth === endMonth ? '' : `${endMonth}월 `

      const startDay = start.day
      const endDay = end.day

      switch (this.type) {
        case 'month':
          return `${startYear}년 ${startMonth}월`
        case 'week':
          return `${startYear}년 ${startMonth}월 ${startDay}일 ~ ${suffixYear}${suffixMonth}${endDay}일`
        case 'day':
          return `${startYear}년 ${startMonth}월 ${startDay}일`
      }
      return ''
    }
  },

  created () {
    // console.log(`In created function: ${this.type}`)

    // !!중요:[2021.10.26] 월~금/일~토 셀렉트를 localStoray에 저장된 것을 불러들이기. 없으면 초기값(월~금)으로 세팅하기
    this.weekdays = storage.get('savedWeekdays')
    if (!this.weekdays) {
      // localStorage 에 지정된 값이 없으면 기본값을 넣어주고 localStorage 에도 저장한다
      this.weekdays = weekdaysDefault
      storage.set('savedWeekdays', weekdaysDefault)
    }

    // !!중요:[2021.10.26] 월간/일간 선택 초기 타입을 여기서 지정해야 2번 적용되지 않는군
    // 저장된 달력 타입을 불러온다. 없는 경우 초기값인 'month' 를 사용하고 localStorage 에도 저장한다
    this.type = storage.get('savedCalendarType')
    if (!this.type) {
      this.type = 'month'
      storage.set('savedCalendarType', this.type)
    }

    // 중요: this.today와 this.focus 를 결정한다
    this.today = this.$moment().format('YYYY-MM-DD')
    this.focus = this.today

    // 중요: import 한 holyday(공휴일) 객체를 지역변수에 담아야 한다
    this.holyDay = holyday

    // 중요: 우선순위 때문에 created 에서 호출해야 한다!
    this.loadSchedule()
  },

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    // if (!this.$store.state.ui.dbcode) {
    //   // this.redirect('/')
    // }

    // * [2022.6.21]만료일 3일 전부터 팝업이 뜨도록 했다. 달력의 날자상으로는 4일전부터 뜨게된다.
    if (this.$store.state.ui && this.$store.state.ui.expDate) {
      // 주의: diff() 주의사항 - days 옵션을 주면 정확히 24시간의 시간차로 날짜를 계산한다. 그러면 종료일이 전날인데 아직 24시간이 지나지 않아 하루가 지나지 않은게 된다.
      //      때문에 endOf('day') 를 사용해 시작을 23:59:59 로 맞춰준다.
      const extDateEndOf = this.$moment(this.$store.state.ui.expDate).endOf('day') // * DB에서 가져온 종료일을 23:59:59로 맞춘것
      const nowDateEndOf = this.$moment().endOf('day') // * 오늘의 날자객체를 23:59:59로 맞춘것
      const diffDays = extDateEndOf.diff(nowDateEndOf, 'days') // * 날자차이 계산 -> 결과는 정수형 데이터임

      if (diffDays <= 3 && diffDays >= 0) { // # 종료일 3일 ~ 0일
        if (diffDays === 0) { // * 0일남았다 = 종료일 당일
          // * [2022.6.21]종료일 당일에도 팝업이 뜬다.
          const nowTODAY = nowDateEndOf.format('YYYYMMDD')
          // # 오늘 날자에 저장된 스토리지 데이터가 없으면 팝업을 띄운다. 단 1일 1회만 뜨도록 조절했다
          if (localStorage.getItem('STORE_SERVICE_TERMINATE_DAY') !== `${nowTODAY}_${diffDays}`) {
            //
            localStorage.removeItem('STORE_SERVICE_TERMINATE_DAY') // localStorage에서 종료일관련 변수를 삭제한다.
            //
            const msg = `서비스 종료일 입니다.<br>본 메시지 확인시에 이미 결제되었을 수도 있습니다.`
            if (this.$refs.ConfirmDialogNoCancel.open('! 알림', msg, { color: 'warning', width: 400 })) {
              // # 새로고침해도 다시 뜨지 않도록 localStorage에 저장한다
              localStorage.setItem('STORE_SERVICE_TERMINATE_DAY', `${nowTODAY}_${diffDays}`)
            }
          }
          //
        } else {
          // * [2022.6.21]만료일 3일 전부터 팝업이 뜬다.(실제로는 4일 전)
          const nowTODAY = nowDateEndOf.format('YYYYMMDD')
          // # 오늘 날자에 저장된 스토리지 데이터가 없으면 팝업을 띄운다. 단 1일 1회만 뜨도록 조절했다
          if (localStorage.getItem('STORE_SERVICE_TERMINATE_DAY') !== `${nowTODAY}_${diffDays}`) {
            //
            localStorage.removeItem('STORE_SERVICE_TERMINATE_DAY') // localStorage에서 종료일관련 변수를 삭제한다.
            //
            const msg = `서비스 종료 ${diffDays}일 전입니다.<br>본 메시지 확인시에 이미 결제되었을 수도 있습니다.`
            if (this.$refs.ConfirmDialogNoCancel.open('! 알림', msg, { color: 'warning', width: 400 })) {
              // # 새로고침해도 다시 뜨지 않도록 localStorage에 저장한다
              localStorage.setItem('STORE_SERVICE_TERMINATE_DAY', `${nowTODAY}_${diffDays}`)
            }
          }
        }
        //
      } else {
        localStorage.removeItem('STORE_SERVICE_TERMINATE_DAY') // localStorage에서 종료일관련 변수를 삭제한다.
      }
    }

    // !![2021.7.19] 관리그룹 select 만들기
    this.setCates('1').then(({ cates }) => {
      if (cates && cates.length > 0) {
        // 관리그룹의 갯수만큼 순회하면서 채운다. 현재 2개만 받을 수 있다.
        for (let cate of cates) {
          this.mgTitle[`${cate.num}`] = cate.gubun1
          const subs = cate.sub.split('|')
          this.select[`mgroup${cate.num}`].push(...subs.map(c => ({ text: c, value: c })))
        }
      }
    })

    // 주의: initVals() 를 여기서 호출하면 updateRange() 보다 우선순위가 늦어 호출하지 않는다.
  },

  methods: {
    cutString,
    numberFormat,
    strDateFormat,
    strDateFormat2,
    strDateFormat3,
    ..._Lwc_.mapActions([
      'setAllTeams',
      'setMyTeams'
    ]),
    // buyStat1, // 구매내역 > 구매상태 사용안함:[2021.4.2 폐기]
    // buyStat2, // 구매내역 > 거래형태 사용안함:[2021.4.2 폐기]
    // settleStat1, // 정산내역 > 정산상태 사용안함:[2021.4.2 폐기]
    // settleStat2, // 정산내역 > 영수증 발행 상태 사용안함:[2021.4.2 폐기]
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 팀정보 에러시 처리하는 함수 - overlay 같은게 여기엔 없다
    async popTeamError (msg) {
      try {
        // 팝업 에러창
        const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        if (await pop.open('접근권한', msg, { color: 'warning', width: 400 })) {
          this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
        } else {
          this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 초기화 함수 - 순수하게 검색 변수만 초기화 시킴
    initVals () {
      try {
        return new Promise((resolve, reject) => {
          this.overlay = true

          // 구분: params 로 백앤드로 전송되는 값
          this.params.draw = 0
          this.params.where = {
            type: 'month',
            startDay: '',
            endDay: '',
            focus: '',
            today: true,
            vtype: []
          }
          this.params.sort = [ 'date1' ]
          this.params.order = [ 'ASC' ] // !! 달력에선 내림차순이 아니다!

          // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
          this.search.sf = 1 // 검색어 검색의 선택된 필드값
          this.search.sw = '' // 검색어 검색의 text box input 값
          this.search.mgroup1 = '' // 관리그룹1 검색
          this.search.mgroup2 = '' // 관리그룹2 검색
          this.search.today = true // 오늘 검색 버튼 클릭값 - true/false
          this.search.star = false // 중요 검색 버튼 클릭값 - true/false
          // this.search.ss1 = '' // 관리상태 셀렉트 선택값
          this.search.ss2 = '' // 담당부서 셀렉트 선택값
          // this.search.ss3 = '' // 정보위치 셀렉트 선택값
          // this.search.ss4 = '' // 항목 셀렉트 선택값
          // this.search.ss5 = '' // 영수증발행여부 셀렉트 선택값

          // 구분: 정렬 기본값(this.params.sort, order와 연계됨)
          this.sort.default = 1

          // 중요: 표시항목 처리
          this.select.vtype.map(m => { m.clicked = false }) // 먼저 모든 선택 해제
          this.search.vtype = [ '211', '212', '221' ]
          // 표시항목을 검색값에 넣어줘야 한다! 그래야 검색이 되쥐 ..
          this.params.where.vtype = this.search.vtype

          // 구분: 담당부서 select - 나의 팀목록 불러오기
          // 비동기 문제로 loadSchdule(), setInitial() 로 나눈다
          // this.getTeams().then(teams => {
          //   if (teams && teams.length > 0) {
          //     this.select.ss2.push(...teams)
          //   }
          // })

          // 구분: 검색어 칩 배열 초기화
          this.searchKeywords = []

          // 구분: 메시지 off
          this.showUpmuMessage = false // 업무자
          // 구분: 각종 권한 메시지 off
          this.showAuthMessage = false // 비용/수금/물품 관리권한 메시지

          // 중요: 얘를 까먹으면 비동기 pending이 풀리질 않는구나!
          resolve(true)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 변수초기화 실행하고 그외 초기화 실행 - 기존 initVals() 의 역할
    async setInitial () {
      try {
        this.initVals().then((res) => {
          // !!중요: localStorage 에 저장된 내용을 삭제한다!
          storage.remove('savedVtype')
          storage.remove('savedScheduleSs2')
          // storage.remove('savedScheduleDefaultSort')

          // 표시항목 체크박스 처리
          for (let value of this.search.vtype) {
            // 해당하는 표시항목에 체크박스를 체크함 상태로 변환한다.
            this.select.vtype.find(m => m.value === value).clicked = true
          }

          // * [2022.10.6]모두 선택되어있으면 전체선택 버튼을 true 로
          this.chkBtnChoiceAll()

          // 구분: 담당부서 select - 나의 팀목록 불러오기
          // 주의: initVals() 에서 1번만 호출하려 했으나 로딩시 저장된 것을 처리하는게 불가능해서 각각 나눴다!
          this.getTeams().then(teams => {
            if (teams && teams.length > 0) {
              this.select.ss2.push(...teams)
            }
          })

          // 검색어 칩 띄우기
          this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))

          // 중요: 오늘로 돌리고 월간모드로 변경
          // 고쳐:[2021.10.25] 이 부분 위의 storage. 처럼 바꾸어야 함
          this.type = 'month'
          this.focus = this.today

          this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시

          sleep(550).then(() => { this.overlay = false }) // shut off overlay
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 일정 초기 로딩시 실행하는 함수 - localStorage에서 저장된 값을 꺼내서 매칭한다!
    async loadSchedule () {
      try {
        this.initVals().then(() => {
          // 중요: 저장된 표시항목 설정 불러오기 > localStorage 에 저장된 표시항목이 있으면 사용
          this.search.vtype = storage.get('savedVtype')
          if (!this.search.vtype) {
            this.search.vtype = [ '211', '212', '221' ]
          }

          // 표시항목 체크박스 처리
          for (let value of this.search.vtype) {
            // 해당하는 표시항목에 체크박스를 체크함 상태로 변환한다.
            this.select.vtype.find(m => m.value === value).clicked = true
          }

          // 여기서 다시! 표시항목을 검색값에 넣어줘야 한다!
          this.params.where.vtype = this.search.vtype

          // * [2022.10.6]모두 선택되어있으면 전체선택 버튼을 true 로
          this.chkBtnChoiceAll()

          // 중요: 담당부서를 설정저장에서 불러오기
          this.search.ss2 = storage.get('savedScheduleSs2') || ''
          if (this.search.ss2) {
            this.params.where.ss2 = this.search.ss2
          }

          // 사용안함:[2021.4.7 폐기]
          // 중요: 정렬을 설정 저장에서 불러오기
          // this.sort.default = storage.get('savedScheduleDefaultSort') || 1
          // if (this.sort.default !== 1) {
          //   this.params.sort = []
          //   this.params.order = []
          //   const sortItem = this.select.defaultSort.filter(c => c.value === this.sort.default)[0]
          //   this.params.sort.push(sortItem.field)
          //   this.params.order.push(sortItem.order)
          // }

          // 검색어 칩 띄우기
          this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))

          // !!중요: 담당부서 검색어 칩 처리 - 원래 initVals() 에서 1번만 호출해 처리하려 했으나
          // 팀의 경우 DB에서 패칭해서 리스트를 뿌리는 방식이라 속도가 늦어져 비동기 식에선 제대로 매치가 안된다.
          // 하여, 불러 들인 후 순차적으로 처리하는 식으로 한다.
          this.getTeams()
            .then(teams => {
              if (teams && teams.length > 0) {
                this.select.ss2.push(...teams)
              }
            })
            .then(() => {
              if (this.search.ss2) { // 저장된 팀이 있으면 로딩!
                const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === 'ss2')
                const sel = this.select.ss2.find(k => k.value === this.search.ss2)
                let skw = { text: sel.text, type: kw.type, name: kw.name, isEver: kw.isEver, isArr: kw.isArr }
                this.searchKeywords.push(skw)
              }
            })

          // !![2021.10.25] 여긴 원본소스
          // !![2021.10.26] created() 로 초기설정을 옮김
          // 중요: 오늘로 돌리고 월간모드로 변경 --> 고쳐:[2021.10.25] 초기가 월간일때만 적용되는 코드
          // this.type = 'month'
          // this.focus = this.today

          // 중요:[2021.10.25 추가] 월~금/일~토 셀렉트를 localStoray에 저장된 것을 불러들이기. 없으면 초기값(월~금)으로 세팅하기
          // this.weekdays = weekdaysDefault // weekdaysAll

          // 중요:[2021.10.25 추가] 월간/일간 선택을 localStoray에 저장된 것을 불러들이기! 없으면 초기값(월간)으로 세팅하기
          // this.type = 'day'
          // this.focus = this.today
          // console.log(this.start)
          // console.log(this.today)

          //  this.$nextTick(() => {
          //   // console.log(this.today) 왜 월 첫날자로 되돌리지?
          //   this.start = new Date()
          //   console.log(this.start)
          // })

          sleep(550).then(() => { this.overlay = false }) // shut off overlay
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 월~금/토~일 셀렉트로 변경 시키는 함수
    // !!중요: 무엇보다 localStorage 에 저장하는 것이 중요하다
    changeWeekdays () {
      if (this.weekdays.length === 5) {
        // 월 ~ 금 까지 5개의 배열요소인 경우 weekdaysDefault 를 저장
        storage.set('savedWeekdays', weekdaysDefault) // !! localStorage 에 저장
      } else {
        // 일 ~ 토 까지 7개의 배열요소인 경우 weekdaysAll 를 저장한다
        storage.set('savedWeekdays', weekdaysAll) // !! localStorage 에 저장
      }
    },
    // 구분: 월간/일간 버튼 클릭으로 변경 시키는 함수
    // !!중요: 무엇보다 localStorage 에 저장하는 것이 중요하다
    changeType () {
      if (this.type === 'month') {
        this.type = 'day'
        storage.set('savedCalendarType', 'day') // !! localStorage 에 저장

        // !! 일간으로 변경시 초기값은 오늘로 한다
        this.today = this.$moment().format('YYYY-MM-DD')
        this.focus = this.today
      } else if (this.type === 'day') {
        this.type = 'month'
        storage.set('savedCalendarType', 'month') // !! localStorage 에 저장
      }
    },
    // 중요: 달력 초기 세팅 및 변경시 시작,끝 세팅 - 해당 달력의 범위를 가지고 DB에서 데이터를 패칭해 옴
    // !! 달력의 type 을 변화시키는 셀렉트는 자동으로 이 함수를 호출한다
    async updateRange ({ start, end }) {
      // console.log(`In updateRange function: ${this.type}`)
      // if (this.overlay) return
      this.overlay = true

      // * 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
      if (this.type === 'month') {
        await sleep(500 - Math.floor(Math.random() * 300))
      } else {
        await sleep(210 - Math.floor(Math.random() * 200))
      }

      // 참고: 넘어온 현재월의 시작일, 마지막일자 세팅
      // start, end 세팅 - 캘린더 이벤트로 넘어온 날짜객체(start, end)를 따로 변수에 담아준다.
      this.start = start
      this.end = end

      // 참고: 전체 검색범위 - 해당월의 prev,next month 의 낀 날짜 구하기 (데이터 패칭시 기준)
      this.startDay = this.start.date
      this.endDay = this.end.date
      if (this.start.weekday > 0) {
        this.startDay = this.$moment(this.start.date).add(`-${this.start.weekday}`, 'days').format('YYYY-MM-DD')
      }
      if (this.end.weekday < 6) {
        this.endDay = this.$moment(this.end.date).add(`${6 - this.end.weekday}`, 'days').format('YYYY-MM-DD')
      }

      // * 이번달/오늘에 따라 이동한 날짜를 따져서 버튼이 선택되게 하기
      if (this.type === 'month') {
        // 월인 경우 - 현재월인 경우 '이번달' 버튼을 선택한다.
        if (this.today.substr(0, 7) === this.start.date.substr(0, 7)) {
          this.search.today = true
        }
      } else if (this.type === 'day') {
        // 일인 경우
        if (this.today === this.start.date) {
          this.search.today = true
        }
      }

      // 주의:!! 너무 깊게 들어가면 호출이 안되나? 아래 this.list() 는 제대로 호출이 안된다.
      // this.$nextTick(async () => {
      //   await this.list()
      // })

      // 중요:!! 검색용 변수 매칭하기 - 원래 list() 에서 할 작업이지만 여기로 뺀다.
      this.params.where.type = this.type
      this.params.where.startDay = this.startDay
      this.params.where.endDay = this.endDay
      this.params.where.focus = this.focus // 일간모드일때 의미있다. 현재 선택된 날임
      this.params.where.today = this.search.today // 별 의미는 없지만 넘겨주자
      this.params.draw++

      // * 비어있는 검색 변수 제거
      for (let key in this.params.where) {
        if (!this.params.where[key]) {
          delete this.params.where[key]
        }
      }
      // console.log(this.params.where)

      // 중요:주의: this.type === 'day' 인 일간모드일때 검색 하는 경우가 있으므로
      // 월간, 일간 모두다 DB에서 패칭이 가능해야 한다
      const { data } = await this.$axios.post(`lawork/lwc/schedule`, this.params)
      if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
      this.datas = data.rObj

      this.overlay = false
    },
    // 사용안함:구분: 일정 리스트 패칭 - 실제 DB 에서 패칭한다
    // 주의: 호출 구조가 너무 깊어서인지 비동기 호출 순위에서 무시되는 건지 updateRange() 에서 제대로 호출이 안된다.
    async list () {
      try {
        // if (this.overlay) return
        // this.overlay = true
        // this.params.draw++
        // this.overlay = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 월간/일간의 아이템 클릭 이벤트 핸들러
    // 아이템의 type 에 따라 팝업을 띄우는 역할을 한다
    async itemClicked (item) {
      try {
        // console.log(`item.type: ${item.type} / item.pType: ${item.pType} / item.subtype: ${item.subtype}`)
        // * item.type 에 따라 팝업을 연다
        if (item.type === 22) { // 업무
          this.viewWorkPop(item)
          //
        } else if (item.type === 23) { // 사용안함:[2021.4.2 폐기] 비용
          // this.viewCostPop(item)
          //
        } else if (item.type === 24) { // 수금
          this.viewSugumPop(item)
          //
        } else if (item.type === 47) { // 사용안함:[2021.4.2 폐기] 물품구매내역
          // this.viewBuyPop(item)
          //
        } else if (item.type === 48) { // 사용안함:[2021.4.2 폐기] 물품정산내역
          // this.viewSettlePop(item)
          //
        } else if (item.type === 21) { // 진행내용
          if (item.pType === 1) {
            this.viewSuitContentPop(item)
          } else if (item.pType === 2) {
            this.viewAdviceContentPop(item)
          }
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 업무 팝업 열기
    async viewWorkPop (item) {
      try {
        // console.log(item)
        // 중요: pId 와 pType 은 무척 중요
        const { pId, pType } = item

        if (pType === 1 || pType === 2) {
          // 부모가 소송 or 자문인 경우
          // !! this.article 을 넘기기 위해 패칭
          const { data } = await this.$axios.get(`lawork/case/getCase/${pId}/${pType}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.article) {
            this.article = data.article

            // 소송이면 caseNum 자문이면 manageNum 을 담는 변수
            const targetNum = pType === 1 ? this.article.caseNum : this.article.manageNum

            // 중요: 2021.4.5 들어갈 수 없는 팀이면 에러를 내고 리스트로 백한다.
            if (this.article.teamError) {
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${targetNum}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
              //
            } else {
              // !! 업무팝업 열기
              const result = await this.$refs.relContentDialog01.open('업무', { width: 650 }, 1, item)
              // 중요: 2021.4.5 수정 - 리턴값이 객체로 변경됨.
              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${targetNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${targetNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // !! 정상적인 수정/삭제인 경우 달력 강제 리프레시
                  this.updateRange({ start: this.start, end: this.end })
                }
              }
            }
          } else {
            // !! 이미 삭제된 데이터인 경우 처리
            await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
          }
          //
        } else if (pType === 3) {
          // 부모가 인명부
          // !! this.article 을 넘기기 위해 패칭
          const { data } = await this.$axios.get(`lawork/client/getClient/${pId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.article) {
            this.article = data.article

            if (this.article.teamError) {
              // 들어갈 수 없는 팀에 속한 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
              //
            } else {
              // !! 업무팝업 열기
              // !![2021.6.25] 고문계약자동갱신인 경우 다른 팝업으로 ..
              let result = null
              if (item.subtype2 === 0) {
                result = await this.$refs.relContentDialog02.open('업무', { width: 650 }, 1, item)
              } else {
                result = await this.$refs.relContentDialog03.open('업무', { width: 650 }, 1, item)
              }
              // 중요: 2021.4.5 수정 - 리턴값이 객체로 변경됨.
              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // !! 정상적인 수정/삭제인 경우 달력 강제 리프레시
                  this.updateRange({ start: this.start, end: this.end })
                }
              }
            }
          } else {
            // !! 이미 삭제된 데이터인 경우 처리
            await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
          }
          //
        } else {
          // 독립메뉴 업무인 경우
          // 중요: // 중요: 독립메뉴는 부모가 없으므로 별다른 처리가 필요없다

          // !! 업무 수정팝업 열기
          // const result = await this.$refs.upmuAddDialog.open('업무', { width: 650 }, 1, item)
          // if (result === 'removed') { // !! 삭제된 경우 리프레시
          //   this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
          // } else { // !! 수정된 경우 리프레시
          //   this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
          // }

          // !![2021.4.5] 삭제던 수정이던 리스트 리프레시 하므로 조건문 불필요
          await this.$refs.upmuAddDialog.open('업무', { width: 650 }, 1, item)
          this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 소송 : 진행내용 팝업열기
    async viewSuitContentPop (item) {
      try {
        // console.log(item)
        const { pId, pType } = item

        // !! this.article 을 넘기기 위해 패칭
        const { data } = await this.$axios.get(`lawork/case/getCase/${pId}/${pType}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.article) {
          this.article = data.article

          if (this.article.teamError) {
            // @: 1 들어갈 수 없는 팀에 속한 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
            //
          } else {
            // @: 2 들어갈 수 있는 데이터인 경우
            if (item.subtype === 1) {
              // 구분: 기일 수정/삭제
              const result = await this.$refs.relJinhengDialog11.open(item.gubun1, { width: 700 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우
                  if (result.rsMsg === 'goNextCourtDay') {
                    // @: 결과가 다음기일추가인 경우 - 기일 추가창을 새로 열어야 한다

                    // 일단 리프레시 한번하고
                    this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시

                    // 중요:[2021.4.5] pInfo 객체를 보내지 않으면 에러가 난다
                    this.article.pInfo = item.pInfo

                    sleep(300).then(async () => {
                      if (await this.$refs.relJinhengDialog11.open('기일', { width: 700 }, item.subtype, -1, null)) {
                        this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                      }
                    })
                  } else if (result.rsMsg === 'edited' || result.rsMsg === 'removed') { // 나머지 경우인 경우(수정성공이나 삭제의 경우)
                    // 나머지 경우인 경우(수정성공이나 삭제의 경우)
                    this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                  }
                }
              }
              //
            } else if (item.subtype === 2) {
              // 구분: 기한 수정/삭제
              // 중요: 송달에서 도달일을 넘겨서 기한추가를 하는 경우가 있어서 다른것과 다르게 맨 마지막에 파라미터가 추가되었다!
              const songdal = { stdDay: '', isGongsiSongdal: false }
              const result = await this.$refs.relJinhengDialog12.open(item.gubun1, { width: 650 }, item.subtype, 1, item, songdal)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우
                  this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                }
              }
              //
            } else if (item.subtype === 5) {
              // 구분: 송달 수정/삭제
              const result = await this.$refs.relJinhengDialog13.open(item.gubun1, { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우
                  if (result.rsMsg === 'edited' || result.rsMsg === 'removed') {
                    // 도달일자가 안넘어온 경우(수정이나 삭제인 경우)
                    this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                    //
                  } else {
                    // 중요: 도달일,공시송달여부객체가 넘어온 경우 - 기한입력창을 열어야 한다!
                    // resolve.rsMsg = { stdDay, isGongsiSongdal } 객체의 형식이다.

                    // 리프레시를 먼저 해야 한다!
                    this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시

                    // 중요:[2021.4.5] pInfo 객체를 보내지 않으면 에러가 난다
                    this.article.pInfo = item.pInfo

                    // 약간의 딜레이를 주고 기한창을 연다
                    sleep(300).then(async () => {
                      const songdal = {
                        stdDay: (result.rsMsg.stdDay) ? result.rsMsg.stdDay : '',
                        isGongsiSongdal: (result.rsMsg.isGongsiSongdal) ? result.rsMsg.isGongsiSongdal : false
                      }
                      if (await this.$refs.relJinhengDialog12.open('기한', { width: 650 }, 2, -1, null, songdal)) {
                        this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                      }
                    })
                  }
                }
              }
              //
            } else if (item.subtype === 8) {
              // 구분: 종국
              const result = await this.$refs.relJinhengDialog14.open(item.gubun1, { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우
                  if (result.rsMsg === 'addLimitDay') {
                    //  결과가 기한추가인 경우 - 기한 등록창을 새로 열어야 한다

                    // 리프레시를 먼저 해야 한다!
                    this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시

                    // 중요:[2021.4.5] pInfo 객체를 보내지 않으면 에러가 난다
                    this.article.pInfo = item.pInfo

                    // 약간의 딜레이를 주고 기한창을 연다
                    sleep(300).then(async () => {
                      if (await this.$refs.relJinhengDialog12.open('기한', { width: 650 }, 2, -1, null, { stdDay: '', isGongsiSongdal: false })) {
                        this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                      }
                    })
                  } else {
                    // 나머지 경우인 경우(수정성공이나 삭제의 경우)
                    this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                  }
                }
              }
              //
            } else {
              // 구분: 그외 - 명령,제출,공고,접수 수정인 경우
              // * [2022.9.22] 내역(subtype=9) 추가됨
              const result = await this.$refs.relJinhengDialog15.open(item.gubun1, { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시 => 삭제인 경우도 자동으로 리프레시된다.
                  this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                }
              }
              //
            }
          }
          //
        } else {
          // !! 이미 삭제된 데이터인 경우 처리
          await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 자문 : 진행내용 팝업열기
    async viewAdviceContentPop (item) {
      try {
        // console.log('자문 > 진행내용 > item.subType: ' + item.subtype)
        const { pId, pType } = item

        // !! this.article 을 넘기기 위해 패칭
        const { data } = await this.$axios.get(`lawork/case/getCase/${pId}/${pType}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.article) {
          this.article = data.article

          if (this.article.teamError) {
            // @: 1 들어갈 수 없는 팀에 속한 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.manageNum}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
            //
          } else {
            // @: 2 들어갈 수 있는 데이터인 경우
            if (item.subtype === 2) {
              // 구분: 마감기한
              // 주의: 소송의 기한과 다르게 마지막에 songdal 객체가 파라미터로 넘어가지 않는다.
              const result = await this.$refs.relJinhengDialog22.open(item.gubun1, { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.manageNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.manageNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시 => 삭제인 경우도 자동으로 리프레시된다.
                  this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                }
              }
              //
            } else if (item.subtype === 8) {
              // 구분: 종국
              // 주의: 자문은 기한추가가 없다. 그러나 나중에 추가할지 모르니 아래의 코드는 그냥 놔둔다.
              const result = await this.$refs.relJinhengDialog24.open(item.gubun1, { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.manageNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.manageNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우
                  if (result.rsMsg === 'addLimitDay') { //  결과가 기한추가인 경우 - 기한 등록창을 새로 열어야 한다
                    // 리프레시를 먼저 해야 한다!
                    this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시

                    // 약간의 딜레이를 주고 기한 등록창을 연다
                    sleep(300).then(async () => {
                      if (await this.$refs.relJinhengDialog22.open('기한', { width: 650 }, 2, -1, null)) {
                        this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                      }
                    })
                  } else {
                    // 그외의 경우(수정이나 삭제)
                    this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                  }
                }
              }
              //
            } else if (item.subtype === 7) {
              // 구분: 자문요청(접수) 수정/삭제인 경우
              const result = await this.$refs.relJinhengDialog25.open('자문요청', { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.manageNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.manageNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시 => 삭제인 경우도 자동으로 리프레시된다.
                  this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                }
              }
              //
            } else {
              // * [2022.9.22] 내역(subtype=9) 추가됨
              const result = await this.$refs.relJinhengDialog25.open(item.gubun1, { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.manageNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.manageNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시 => 삭제인 경우도 자동으로 리프레시된다.
                  this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                }
              }
              //
            }
          }
          //
        } else {
          // !! 이미 삭제된 데이터인 경우 처리
          await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 수금 팝업 열기
    // 중요: 인명부는 상관없이 열면 된다! 그러나 소송/자문은 연계의뢰인 정보를 넘겨야 한다!
    async viewSugumPop (item) {
      try {
        // console.log(item)
        // 중요: pId 와 pType 은 무척 중요
        const { pId, pType } = item

        if (pType === 1 || pType === 2) {
          // 부모가 소송 or 자문인 경우
          // @: this.article 을 넘기기 위해 패칭
          const { data } = await this.$axios.get(`lawork/case/getCase/${pId}/${pType}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.article) {
            this.article = data.article

            // 소송인 경우 사건번호를 자문인경우 관리번호를 변수에 매칭한다.
            const cmNum = pType === 1 ? this.article.caseNum : this.article.manageNum

            if (this.article.teamError) {
              // 들어갈 수 없는 팀에 속한 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${cmNum}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
              //
            } else {
              // @:중요: 연계 의뢰인 배열 만들기
              let relCls = await this.getRelClientArray(item)

              // !! 팝업열기
              const result = await this.$refs.relSugumDialog01.open('수금', { width: 600 }, 1, item, relCls)
              if (result) {
                if (result.isDeleted) {
                  // 부모가 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${cmNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${cmNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // !! 정상적으로 수정/삭제된 경우 리프레시
                  this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                }
              }
            }
          } else {
            // !! 이미 삭제된 데이터인 경우 처리
            await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
          }
          //
        } else if (pType === 3) {
          // 부모가 인명부
          // !! this.article 을 넘기기 위해 패칭
          const { data } = await this.$axios.get(`lawork/client/getClient/${pId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.article) {
            this.article = data.article

            if (this.article.teamError) {
              // 들어갈 수 없는 팀에 속한 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
              //
            } else {
              // !! 팝업 열기
              const result = await this.$refs.relSugumDialog02.open('수금', { width: 600 }, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 부모가 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // !! 정상적으로 수정/삭제된 경우 리프레시
                  this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
                }
              }
            }
          } else {
            // !! 이미 삭제된 데이터인 경우 처리
            await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
          }
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함:[2021.4.2 폐기] 비용 팝업 열기
    // 중요: 인명부는 상관없이 열면 된다! 그러나 소송/자문은 연계의뢰인 정보를 넘겨야 한다!
    // async viewCostPop (item) {
    //   try {
    //     // console.log(item)
    //     // 중요: pId 와 pType 은 무척 중요
    //     const { pId, pType } = item

    //     if (pType === 1 || pType === 2) {
    //       // 부모가 소송 or 자문인 경우
    //       // @: this.article 을 넘기기 위해 패칭
    //       const { data } = await this.$axios.get(`lawork/case/getCase/${pId}/${pType}`)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
    //       this.article = data.article

    //       // @:중요: 연계 의뢰인 배열 만들기
    //       let relCls = await this.getRelClientArray(item)

    //       // !! 팝업열기
    //       const result = await this.$refs.relCostDialog01.open('비용', { width: 600 }, 1, item, relCls)
    //       if (result === 'removed') {
    //         // !! 삭제된 경우 리프레시
    //         this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
    //       } else {
    //         // !! 수정된 경우 리프레시
    //         this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
    //       }
    //       //
    //     } else if (pType === 3) {
    //       // 부모가 인명부
    //       // !! this.article 을 넘기기 위해 패칭
    //       const { data } = await this.$axios.get(`lawork/client/getClient/${pId}`)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
    //       this.article = data.article

    //       // !! 업무팝업 열기
    //       const result = await this.$refs.relCostDialog02.open('비용', { width: 600 }, 1, item)
    //       if (result === 'removed') {
    //         // !! 삭제된 경우 리프레시
    //         this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
    //       } else {
    //         // !! 수정된 경우 리프레시
    //         this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
    //       }
    //       //
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 사용안함:[2021.4.2 폐기]물품구매내역 팝업 열기
    // async viewBuyPop (item) {
    //   try {
    //     // console.log(item)
    //     // !!중요: 부모인 거래처 정보를 패칭해서 this.article 에 매칭한다!
    //     const { data } = await this.$axios.get(`lawork/lwc/getShopById/${item.pId}`)
    //     if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
    //     this.article = data.article

    //     // > 수정 모드만 있다(등록은 당연히 없음)
    //     const result = await this.$refs.purchaseDialog.open('구매내역', { width: 650 }, 1, item)
    //     if (result.type === 'addSettlement') {
    //       // @: 결과가 정산내역추가인 경우 - 정산내역창을 열어야 한다
    //       if (result.data) { // 리턴된 데이터가 있으면 정산내역창에 같이 보낸다!
    //         // 약간의 딜레이를 주고 정산내역창을 연다
    //         sleep(550).then(() => { this.popSettlement(null, result.data) })
    //       }
    //       //
    //     }
    //     this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 사용안함:[2021.4.2 폐기] 정산내역 등록 팝업 - 물품구매내역에서 정산내역추가시 사용하는 함수
    // async popSettlement (item, rData) {
    //   try {
    //     if (await this.$refs.settleDialog.open('정산내역', { width: 550 }, -1, item, rData)) {
    //       this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 사용안함:[2021.4.2 폐기] 물품정산내역 팝업 열기
    // async viewSettlePop (item) {
    //   try {
    //     // console.log(item)
    //     // !!중요: 부모인 거래처 정보를 패칭해서 this.article 에 매칭한다!
    //     const { data } = await this.$axios.get(`lawork/lwc/getShopById/${item.pId}`)
    //     if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
    //     this.article = data.article

    //     // > 수정 모드만 있다(등록은 당연히 없음)
    //     if (await this.$refs.settleDialog.open('정산내역', { width: 550 }, 1, item, null)) {
    //       this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분:중요: 연계 의뢰인 정보로 넘겨줄 배열을 만드는 함수
    // 비용/수금에서 사용한다!
    async getRelClientArray (item) {
      try {
        let returnArray = []

        // !! 연계 의뢰인 정보를 패칭한다. pId, pType 을 넘기는게 중요하다!
        const { data } = await this.$axios.get(`lawork/lwc/getRelClients/${item.pId}/${item.pType}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        let relClients = data.list
        if (relClients) {
          relClients.forEach(item => {
            // !![2021.4.2] item.rInfo 는 인명부의 객체문자열이므로 파싱해야 한다.
            const rInfo = JSON.parse(item.rInfo)
            returnArray.push({
              text: `${rInfo.name} ${item.str1 ? `${item.str1}` : item.str2 ? `${item.str2}` : ''}`,
              value: item.rId,
              name: rInfo.name
            })
          })
        }
        return returnArray
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 토,일,공휴일 날짜 색을 변경하기
    // 두 번째 인자인 type 에 class 를 주면 color--text 로 글자색을 변경한다.
    setDayColor (day, type = '') {
      // label.weekday === 0 ? 'error' : label.weekday === 6 ? 'primary' : ''
      if (day.weekday === 0 || this.holyDay[day.date]) {
        return type === 'class' ? 'error--text' : 'error'
      } else if (day.weekday === 6) {
        return type === 'class' ? 'primary--text' : 'primary'
      } else {
        return type === 'class' ? 'black--text' : 'grey darken-3'
      }
    },
    // 구분: '이번달/오늘' 세팅
    setToday () {
      this.focus = this.today
      this.search.today = true // !! 이넘을 잊지말자

      // this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
      this.$refs.calendar.checkChange() // !! 이게 더 정확 -- '이번달/오늘'이 아닌 경우만 이벤트 발생시킴
    },
    // 구분: 이전 - 자동리프레시됨
    prev () {
      this.search.today = false // !! 오늘 선택을 무조건 없앤다
      this.$refs.calendar.prev()
    },
    // 구분: 다음 - 자동리프레시됨
    next () {
      this.search.today = false // !! 오늘 선택을 무조건 없앤다
      this.$refs.calendar.next()
    },
    // 구분: 월간 타입일 때 날짜 라벨 클릭하면 발생하는 이벤트 -> 일간으로 이동
    viewDay (d) {
      // !! 월간/주간일때 날짜 클릭하면 해당 일로 간다.
      if (this.type === 'month' || this.type === 'week') {
        this.focus = d.date
        this.type = 'day'

        // !! 클릭한 날이 오늘이 아니면 '오늘' 버튼 선택을 해제
        this.search.today = false // !! 오늘 선택을 무조건 없앤다
      }
      // 주의: 주간이 없어져서 아래 코드는 주석처리됨
      // else if (this.type === 'day') { // !! 일간일 때는 주간으로 간다.
      //   this.focus = d.date
      //   this.type = 'week'
      // }
      // !! 일간 일정의 @click.stop="viewDay({ date, day })" 코드도 삭제되었음
    },
    // 사용안함: 충돌 같은건 없지만 각 영역의 아이템을 클릭시 발생하는 이벤트가 아니라서 필요없다
    // 구분: 월간/주간/일간의 date 영역을 클릭하면 발생하는 이벤트.(날짜 라벨이 아닌 주변영역)
    // clickDay (day) {
    //   console.log(`(${this.type}) In clickDay : ${day.day} - ${day.date}`)
    // },
    // 사용안함: viewDay() 와 같이 사용하면 충돌이 나고 무시된다.
    // 구분: date 영역의 숫자를 클릭 하면 발생하는 이벤트(주간,일간에서 사용)
    // clickDate (d) {
    //   if (this.type === 'week') {
    //     // !! 주간일때 날짜 클릭하면 해당 일로 간다.
    //     this.focus = d.date
    //     this.type = 'day'
    //   } else if (this.type === 'day') {
    //     // !! 일간일 때는 주간으로 간다.
    //     this.focus = d.date
    //     this.type = 'week'
    //   }
    // },
    // !! month 모드에서 해당월이 아닌 경우 바탕색을 회색으로 변경
    monthPastColor (date) {
      if (!this.start) return ''
      return this.start.month !== Number(date.substr(5, 2)) ? 'grey lighten-4' : ''
    },
    // 구분: datepicker 로 날짜를 선택한 경우
    dateSelect (start) {
      this.$refs.dateSelector.save(start)
      this.type = 'day'
      this.search.today = false // !! 오늘 선택을 무조건 없앤다
    },
    // 구분: -- 검색처리 메소드 모음
    // 팝업 검색 버튼 클릭 이벤트 핸들러
    async searchPopBtn () {
      try {
        // 구분: 각종 권한 메시지 off
        this.showAuthMessage = false // 비용/수금/물품 관리권한 메시지

        let authResult = true // 권한체크 플래그 변수

        this.select.vtype[9].chipColor = 'light-blue lighten-5' // 수금

        // this.select.vtype[9].chipColor = 'light-blue lighten-5' // 비용 사용안함:[2021.4.2 폐기]
        // this.select.vtype[11].chipColor = 'light-blue lighten-5' // 물품구매내역 사용안함:[2021.4.2 폐기]
        // this.select.vtype[12].chipColor = 'light-blue lighten-5' // 물품정산내역 사용안함:[2021.4.2 폐기]

        // 사용안함:[2021.4.2 폐기] 비용 권한체크
        // if (this.search.vtype.includes('231')) {
        //   const { data } = await this.$axios.get(`lawork/lwc/getStaffAuths`)
        //   if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        //   if (!data.auths.auth2) {
        //     this.select.vtype[9].chipColor = 'error'
        //     authResult = false
        //   }
        // }

        // !! 수금/계약 권한체크
        if (this.search.vtype.includes('241')) {
          const { data } = await this.$axios.get(`lawork/lwc/getStaffAuths`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (!data.auths.auth1) {
            this.select.vtype[9].chipColor = 'error'
            authResult = false
          }
        }

        // 사용안함:[2021.4.2 폐기] 물품구매내역(471), 물품정산내역(481)에 체크되었으면 물품관리권한이 있는지 체크해야 한다!
        // if (this.search.vtype.includes('471') || this.search.vtype.includes('481')) {
        //   const { data } = await this.$axios.get(`lawork/lwc/getAllTeamIds`)
        //   if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        //   if (!data.auths.t2) {
        //     if (this.search.vtype.includes('471')) this.select.vtype[11].chipColor = 'error'
        //     if (this.search.vtype.includes('481')) this.select.vtype[12].chipColor = 'error'
        //     authResult = false
        //   }
        // }

        if (!authResult) {
          this.showAuthMessage = true
          return
        }

        // !! 검색어 검색 처리
        await this.searchWord()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[2021.9.28]팝업검색시 돋보기 아이콘을 클릭할때 검색처리 - 검색어가 없으면 아무것도 안함
    async searchPopIcon () {
      try {
        // 사용안함:검색어가 있는 경우만 searchPopBtn() 를 실행
        // if (this.search.sw) {
        //   await this.searchPopBtn()
        // }
        await this.searchPopBtn()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 검색시 표시항목 체크를 전체선택으로 변경
    async btnChoiceAll () {
      try {
        //
        this.choicAll = !this.choicAll
        if (!this.choicAll) {
          // * 전체해제를 할 경우 초기값으로 회귀한다.
          this.select.vtype.map(m => { m.clicked = false }) // 먼저 모든 선택 해제
          this.search.vtype = [ '211', '212', '221' ] // 초기값은 기일,기한,업무
          this.params.where.vtype = this.search.vtype
          // 표시항목 체크박스 처리
          for (let value of this.search.vtype) {
            // 해당하는 표시항목에 체크박스를 체크함 상태로 변환한다.
            this.select.vtype.find(m => m.value === value).clicked = true
          }
        } else {
          // * 전체선택시
          this.select.vtype.map(m => { m.clicked = true }) // 먼저 모든 선택 활성화
          this.search.vtype = this.select.vtype.map(m => m.value) // 모든 value 를 배열로
          this.params.where.vtype = this.search.vtype
        }
        this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 버튼[검색] - true/false 검색 버튼 처리 함수
    // !! 버튼은 눈에 띄는 구분이 있기 때문에 검색어 태그 목록을 만들지 않는다
    async btnClick (elem) {
      try {
        // this.search[elem] = !this.search[elem]
        // this.params.where[elem] = this.search[elem]
        // this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시

        // 중요: 원래는 위의 3줄처럼 간단해야 하지만 달력의 특성상 오늘 버튼에 대한 특별한 처리가 필요하다.
        if (elem === 'today') { // '오늘'을 클릭한 경우
          // 같은 오늘을 여러번 클릭해도 변동은 없다.
          if (this.focus !== this.today) {
            this.search[elem] = !this.search[elem]
            this.params.where[elem] = this.search[elem]
            if (this.search[elem]) {
              this.setToday() // !! setToday() 에서 리프레시 시킨다
            }
          }
        } else {
          // '오늘' 이외의 경우
          this.search[elem] = !this.search[elem]
          this.params.where[elem] = this.search[elem]
          this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [검색] - 셀렉트 검색 처리 메소드
    async selectChange (elem) {
      try {
        this.params.where[elem] = this.search[elem]

        if (elem === 'ss2') {
          // 중요:!! 담당부서 선택 설정 저장
          storage.set('savedScheduleSs2', this.params.where[elem])
        }

        // !! 검색어 칩 처리 - 타입은 검색 - search && name = elem
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === elem)
        await this.setSearchKeywords(kw)

        // !! 달력 강제 리프레시
        this.updateRange({ start: this.start, end: this.end })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [검색] - 체크박스 클릭처리 메소드
    // 주의: isEver=true 인 경우는 최소한 1개는 선택되어지도록 처리된다.
    async checkboxClick (elem, item) {
      try {
        // 주의: 업무자 선택시 업무에 체크된것 이외는 무시(이미 업무에 체크되어있음)
        if (this.search.sf === 3) return

        // !! 재활용하기 위해 일단 현재 검색어 객체를 받아놓기
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === elem)

        item.clicked = !item.clicked // 클릭처리

        if (item.clicked) { // true 면 this.search[elem] 배열에 push
          // 넣기전에 중복검사. 없으면 넣는다.
          if (this.search[elem].indexOf(item.value) === -1) {
            this.search[elem].push(item.value)
          }
          //
        } else { // false 면 this.search[elem] 배열에서 삭제
          // 주의: 삭제시는 조금 복작 - kw.isEver 인 경우와 아닌 경우를 따져야 하네... 귀찮
          if (kw.isEver) { // kw.isEver=true 면 배열의 길이가 1보다 큰 경우만 삭제
            if (this.search[elem].length > 1) {
              const idx = this.search[elem].findIndex(n => n === item.value)
              this.search[elem].splice(idx, 1)
            } else {
              // !! 길이가 1이거나 작으면 클릭을 이전으로 복구해야 한다!
              item.clicked = !item.clicked
            }
          } else { // kw.isEver=false 면 그냥 삭제해도 무방
            const idx = this.search[elem].findIndex(n => n === item.value)
            this.search[elem].splice(idx, 1)
          }
        }

        // * [2022.10.6]모두 선택되어있으면 전체선택 버튼을 true 로
        this.chkBtnChoiceAll()

        this.params.where[elem] = this.search[elem] // 검색용 파라미터 처리
        await this.setSearchKeywords(kw) // 검색어 칩 처리
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [검색] - 검색어 검색 처리 함수
    // !! 검색 버튼 클릭이벤트핸들러에서 호출하는 함수이기도 함.
    async searchWord () {
      try {
        if (this.search.sw.length > 0) { // 검색어가 있으면 파라미터에 넣고
          this.params.where.sf = this.search.sf
          this.params.where.sw = this.search.sw
        } else { // 없어도 일단 넣지만 값을 비운다.
          this.params.where.sf = ''
          this.params.where.sw = ''
        }

        // !! 검색어 칩 처리 - type = search  && name = sw
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === 'sw')
        await this.setSearchKeywords(kw)

        // 중요: 로컬스토리지에 검색설정을 저장
        storage.set('savedVtype', this.params.where.vtype)

        // !! 달력 강제 리프레시
        this.updateRange({ start: this.start, end: this.end })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 정렬처리 메소드 모음 ----
    // this.select.{가변이름}Sort 로 여러개의 소트를 처리할 수 있다.
    // 여러개의 소트가 있어도 처리 가능하다
    // this.params.sort 를 초기화 하고 모든 소트를 새로 만든다.
    async selectOrder () {
      try {
        // 초기화
        this.params.sort = []
        this.params.order = []

        for (let key in this.sort) {
          const selectSortValue = this.sort[key]
          const field = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].field
          const order = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].order

          this.params.sort.push(field)
          this.params.order.push(order)

          // 검색어 칩 - type = sort(정렬)  && name = elem
          const kw = this.useSearchKeywords.find(k => k.type === 'sort' && k.name === key)
          await this.setSearchKeywords(kw)
        }

        // 중요: 로컬스토리지에 정렬설정을 저장 - this.select.defaultSort 의 값을 저장한다.
        // storage.set('savedScheduleDefaultSort', this.sort.default)

        // !! 달력 강제 리프레시
        this.updateRange({ start: this.start, end: this.end })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 검색팝업 > 검색어 검색 셀렉트 change event handler
    // 업무자 선택하면 표시항목에 '업무' 만 선택되도록 하는게 임무임!
    async changeSearchField () {
      try {
        if (this.search.sf === 3) { // !! 업무자를 선택한 경우만 의미가 있다
          this.showUpmuMessage = true
          this.select.vtype.map(m => { m.clicked = false }) // 먼저 모든 표시항목 선택 해제
          this.search.vtype = [ '221' ]
          this.params.where.vtype = this.search.vtype
          // 표시항목 체크박스 처리 > 업무를 체크함 상태로 변환한다.
          this.select.vtype.find(m => m.value === '221').clicked = true
          // 표시항목 검색어 칩 처리 > 업무만 남겨둔다
          const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === 'vtype')
          await this.setSearchKeywords(kw)
        } else {
          this.showUpmuMessage = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색어칩 처리 메소드 모음
    // 로딩시 보여줄 검색어 칩을 처리하는 메서드
    async loadSearchKeywords (kw) {
      try {
        if (!Array.isArray(kw)) throw new Error('[Array Not Found]loadSearchKeywords(): 잘못된 변수전달 방식입니다.')
        kw.forEach(async (k) => {
          await this.setSearchKeywords(k)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:중요: 검색어 칩을 보여주는 처리를 하는 메서드
    async setSearchKeywords (kw) {
      try {
        // this.searchKeywords 배열에 등록될 객체의 뼈대
        let skw = { text: '', type: kw.type, name: kw.name, isEver: kw.isEver, isArr: kw.isArr }
        // console.log(skw)

        // 기존 같은 타입과 이름의 배열이 있으면 삭제
        const index = this.searchKeywords.findIndex(k => k.type === kw.type && k.name === kw.name)
        if (index > -1) {
          this.searchKeywords.splice(index, 1)
        }

        // 현재값
        const currVal = this[kw.type][kw.name] || ''

        if (kw.isArr) { // 구분: 다중검색(배열검색) 인 경우
          if (!Array.isArray(currVal)) throw new Error('[Array Not Found]setSearchKeywords(): 잘못된 변수전달 방식입니다.')
          let txtArr = []
          // select 를 사용하는 경우 this.select[kw.select] 로 해당 값을 조회한다.
          if (kw.select) {
            for (let cv of currVal) {
              txtArr.push(this.select[kw.select].find(k => k.value === cv).text)
            }
          } else {
            // @: 아직 쓰이는데가 없음
            // select 를 사용하지 않는 경우 currVal 을 순회하면서 값을 바로 매칭
            for (let cv of currVal) {
              txtArr.push(cv)
            }
          }
          skw.text = txtArr.join(',')
          //
        } else { // 구분: sort, search 같은 단일 검색인 경우
          // select 가 있으면 select 에서 보여줄 text 를 가져온다
          if (kw.select) {
            const sel = this.select[kw.select].find(k => k.value === currVal)
            // console.log(sel)
            skw.text = (sel.value) ? sel.text : ''
          } else {
            // select 가 아닌 text 입력값은 현재값을 바로 매칭한다.
            // showSelect 가 지정된 경우 해당 셀렉트의 text 를 보여준다.
            if (kw.showSelect) {
              if (currVal) { // 값이 있어야 넣어준다
                skw.text = `${this.select[kw.showSelect].find(k => k.value === this.search[kw.showSelect]).text} - "${currVal}"`
              } else {
                skw.text = ''
              }
            } else {
              if (currVal) { // 값이 있어야 넣어준다
                skw.text = `"${currVal}"`
              } else {
                skw.text = ''
              }
            }
          }
          //
        }

        if (skw.text) {
          this.searchKeywords.push(skw)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 검색어 칩을 닫는 메서드
    async closeSearchKeyword (chip) {
      try {
        if (!chip.isEver) { // isEver = true 인 넘은 없앨 수 없다. false 인 경우만 처리
          const kw = this.useSearchKeywords.find(c => c.type === chip.type && c.name === chip.name)
          if (kw.select) {
            // 셀렉트 검색인 경우
            // this.select.sido = '' 처럼 셀렉트의 가장 처음값을 초기값으로 보고 변경시킨다.
            this[kw.type][kw.name] = this.select[kw.name][0].value
            await this.selectChange(kw.name)
          } else {
            //  검색어 검색인 경우
            if (kw.type === 'search' && kw.name === 'sw') {
              this[kw.type][kw.name] = ''
              await this.searchWord()
            }
          }
          // 중요: 일정에서만 - 검색어 칩을 닫을 때 검색셀렉트를 '내용'으로 초기화 시켜야 한다.
          // '업무자'를 선택한 경우 표시항목이 '업무'로 고정되므로 유저가 혼란을 느껴서..
          this.search.sf = 1
          this.showUpmuMessage = false // 업무자
          this.showAuthMessage = false // 비용/수금/물품 관리권한 메시지
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 유틸리티 함수 모음 -------
    // 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 팀목록 패칭
    // * data.allTeams 는 모든팀 목록 - vuex lwc 모듈에 저장용
    // * data.myTeams 는 소속된 팀목록(관리자팀,물품구매팀,회의실관리팀은 제외)
    // * data.teams 는 셀렉트를 위한 [{ text, value },] 배열임
    // !![2021.4.30]data.myTeams 도 리턴받게 수정함 - vuex에 저장
    async getTeams () {
      try {
        const { data } = await this.$axios.get('lawork/case/getMyBasicTeamInfo')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        // 중요: 내가 속한 팀을 vuex lwc 모듈에 저장
        await this.setMyTeams(data.myTeams)

        // !![2021.4.30] 모든 팀을 vuex lwc 모듈에 저장
        await this.setAllTeams(data.allTeams)

        return data.teams
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 부모 바로가기 - 소송/자문/인명부로 간다.
    async parentDirectGo (item) {
      try {
        const mLink = {
          1: 'case', 2: 'advice', 3: 'client'
        }
        this.$router.push(`/${mLink[item.pType]}/${item.pId}`)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[2022.10.6]모두 선택되어있으면 전체선택 버튼을 true 로
    chkBtnChoiceAll () {
      if (this.search.vtype.length === this.select.vtype.length) {
        this.choicAll = true
      } else {
        this.choicAll = false
      }
    },
    // 사용안함: pdf 생성 함수 1 - 이미지 캡쳐
    // 사용안함: 컨트롤이 제대로 안되서 사용하지 않는다
    async pdfgen () {
      try {
        const elem = document.querySelector('#myCalendar')
        if (!elem) throw new Error(`요청하는 엘리먼트가 없습니다.`)

        this.overlay = true

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: `${this.title} 일정`,
          subject: `${this.title} 일정`
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfListStyle
        this.pdfDoc.defaultStyle = { font: 'Nanum' }
        // 중요: 이미지는 세로로만
        this.pdfDoc.pageOrientation = 'portrait'

        // BASE64 txt data 로 변형된 이미지로 만든다!
        let canvas = await html2canvas(elem)
        let imgData = await canvas.toDataURL('image/png', 2.0)

        // 중요: a4 용지는 pt 사이즈로 595.28 x 842.89 임
        // 중요: 현재 내가 할 수 있는건 이미지가 width=565, height=780 기준으로 1페이지에 담도록 하는 것뿐!
        // width 는 565 로 고정
        // height / 4가 780 을 넘으면 780 으로 고정, 작으면 Math.ceil(ch / 4) 로 해서
        // 대략적인 비율이 맞도록 했다!
        let cw = canvas.width
        let ch = canvas.height
        // console.log(`${cw} x ${ch}`)

        cw = 565
        ch = ch / 4 >= 780 ? 780 : Math.ceil(ch / 4)

        this.pdfDoc.content = [
          {
            text: this.title,
            style:
            {
              fontSize: 9,
              alignment: 'right',
              lineHeight: 1
            }
          },
          // {
          //   image: imgData,
          //   width: 565
          // }
          {
            // style: 'tableBody2',
            table: {
              widths: ['*'],
              // dontBreakRows: true,
              body: [
                [
                  {
                    stack: [
                      {
                        image: imgData,
                        width: cw, // 565,
                        height: ch // 780
                      }
                    ]
                  }
                ]
              ]
            }
          }
        ]
        pdfMake.createPdf(this.pdfDoc).open()

        // shut off overlay
        sleep(350).then(() => { this.overlay = false })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 생성 함수2 - 데이터 형
    async pdfgen2 () {
      try {
        this.overlay = true

        // * pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: `${this.title} 일정`,
          subject: `${this.title} 일정`
        }
        // * 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfViewStyle // 중요: 일정은 리스트와 뷰 스타일 모두 사용
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // # 월간 Start
        if (this.type === 'month') {
          // 중요: 월간이면 가로로
          this.pdfDoc.pageOrientation = 'landscape'

          // @: 헤더 타이틀
          const pdfHeaderTitle = `${this.title} 일정`
          // @: 작성일
          // const workDate = `작성: ${this.$moment().format('YYYY.MM.DD HH:mm')}`

          // 중요: this.$refs.calendar.$children[0].days 에 월간일정에 필요한 모든 일자 데이터가 들어있다.
          let monthContent = this.$refs.calendar.$children[0].days

          // * 테이블 껍데기
          let tbl = {
            // style: 'tableBody',
            layout: { // 중요: 테이블 레이아웃을 조절한다.
              // hLineWidth: function hLineWidth (i) { return 0 },
              // vLineWidth: function vLineWidth (i) { return 0 },
              paddingLeft: function (i, node) { return 5 },
              paddingRight: function (i, node) { return 5 },
              paddingTop: function (i, node) { return 5 },
              paddingBottom: function (i, node) { return 8 }
            },
            table: {
              widths: null,
              body: []
            }
          }

          // * this.weekdays 를 순회하면서 기본적인 테이블 헤더를 만든다.
          let tWidths = [] //
          let tHeaders = []
          this.weekdays.forEach(d => {
            // * td 의 갯수지정. 월 ~ 금, 일 ~ 토 여부에 따라 5일짜리, 7일 짜리로 분류된다.
            tWidths.push('*')

            // 테이블 헤더 만들기
            tHeaders.push({ text: this.yoilHan[d], style: 'tableHeader', alignment: 'center' })
          })
          tbl.table.widths = tWidths // td 갯수 와 넓이 대입
          tbl.table.body.push(tHeaders) // 테이블 헤더에 요일대입

          let tBody = [] // 각 tr 에 해당하는 내용
          const weekLen = this.weekdays.length // 한주의 길이. 5이거나 7

          // 중요: 모든 일자를 순회하면서 달력을 만든다.
          // 각 주의 마지막 요일(금 이거나 토)에 다다르면 새로운 주배열을 생성한다.
          // pdfmake 의 table 구조가 한 주씩 배열에 넣는 구조라 그에 맞게 생성한다.
          for (let i = 0; i < monthContent.length; i++) {
            let text = `${monthContent[i].day}\n` // 내용에 제일먼저 날짜를 넣는다.

            // * 해당 일자의 데이터가 있는 경우 패칭
            let cData = this.datas[monthContent[i].date]
            if (cData) {
              let n = 1
              let cLen = cData.length
              cData.forEach(item => {
                // 맨 앞쪽 중요표시등 처리
                text += `${item.isStar ? '★ ' : ''}`
                if (item.vtype === '241') { // # 비용/수금인 경우 - 비용은 폐기됨
                  // text += `[${item.gubun1}] `
                  // [2021.4.6] 수금만 수금으로 표기
                  text += `【수금】 `
                } else if (item.vtype === '471' || item.vtype === '481') {
                  // 사용안함:[2021.4.2 폐기] 구매내역/정산내역인 경우
                  // text += `[${item.gubun1.substr(0, 2)}] `
                } else if (item.vtype === '221') {
                  // [2021.4.6 추가] 업무만 앞대가리 타이틀 붙여줌. 진행내용은 폐기
                  text += `【${item.gubun1}】 `
                } else {
                  // 그외의 경우 [2021.4.6 수정됨]
                  // 원래 나머지 진행내역, 업무를 나타냈으나 폐기됨
                  // text += `[${item.gubun1}] `
                }

                // @:구분:@: 각 메뉴의 주요내용 영역 1 - 앞대가리
                if (item.vtype === '221') {
                  // # 업무
                  text += `${item.str2 ? `${item.str2} ` : ''}${item._place_time_ ? `${item._place_time_}` : ''}` // 업무자 + 장소/시간
                  text += `\n${item.str1 ? cutString(item.str1) : ''}` // 내용
                } else if (item.vtype === '211') {
                  // # 기일
                  // <b>..</b> 태그를 지워야 하네 젠장.. 왜 이렇게 해가지고.. 쩝
                  item._place_time_ = item._place_time_.replace('<b>', '').replace('</b>', '')
                  text += `${item.str1} ${item._place_time_}` // 기일명 + 장소/시간
                } else if (item.vtype === '212') {
                  // # 기한
                  text += item.str1 // 기한명
                } else if (item.vtype === '215') {
                  // # 송달
                  text += `【${item.gubun1}】 ${item.str1}`
                  //
                } else if (item.vtype === '218') {
                  // # 종국
                  text += `【${item.gubun1}】 ${item.view1}`
                } else if (item.vtype === '241') {
                  // # 수금
                  text += (item.gubun2 === '미발행') ? `[미발행] ` : ''
                  text += item.str2 ? `￦${numberFormat(item.str2)}` : ''
                  text += item.rInfo ? ` - ${item.rInfo}` : ''
                  //
                } else {
                  // # 그외: 명령, 제출, 공고, 접수, 내역
                  text += `【${item.gubun1}】 ${item.str1}`
                }

                // @:구분:@: 연계된 부모정보 - 중간에 보임
                if (item.pType === 1) {
                  // # 소송
                  text += `\n${item.pInfo.courtName} ${item.pInfo.caseNum}${item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : ''}`
                  text += `\n${item._dcl_}${item._dcl_ && item._dop_ ? `/${item._dop_}` : item._dop_} `
                } else if (item.pType === 2) {
                  // # 자문
                  text += `\n${item.pInfo.manageNum}`
                  text += `\n${item._dcl_}${item._dcl_ && item._dop_ ? `/${item._dop_}` : item._dop_} `
                } else if (item.pType === 3) {
                  // # 인명부
                  text += `\n${item.pInfo.name} `
                } else {
                  // # 업무인 경우: 독립업무 뷰
                  text += item.vtype === '221' ? `\n[독립업무] ` : ''
                }
                // * 관리그룹 & 팀 붙이기
                text += `${item._groups_ ? `\n - ${item._groups_}` : ''}${item.teamName ? `\n[${item.teamName}]` : ''}`

                // @:구분:@: 각 메뉴의 주요내용 영역 2 - 뒷꼭지
                if (item.vtype === '221') {
                  // # 업무
                  text += `\n#${item.gubun2}` // 업무관리상태
                } else if (item.vtype === '211') {
                  // # 기일
                  text += item.str2 ? `\n${item.str2}` : '' // 결과
                  text += item._chk_end_ ? `\n${item._chk_end_}` : '' // 종국확인
                  text += item.gubun2 ? `\n#${item.gubun2}` : '' // 예정
                } else if (item.vtype === '212') {
                  // # 기한
                  text += item.str2 ? `\n${item.str2}` : '' // 대상자
                } else if (item.vtype === '214') {
                  // # 제출 - [2022.9.20 추가]제출결과 추가
                  text += item.str2 ? `\n${item.str2}` : ''
                } else if (item.vtype === '215') {
                  // # 송달
                  text += (item.date2 && item.str2) ? `\n${strDateFormat(item.date2)} ${item.str2}` : ''
                } else if (item.vtype === '218') {
                  // # 종국
                  text += item.view2 ? `\n${item.view2}` : ''
                } else if (item.vtype === '241') {
                  // # 수금
                  text += item._sugum_delay_ ? `\n[${item._sugum_delay_}]` : '' // 수금지체
                  text += item.gubun3 ? `\n#${item.gubun3}` : '' // 관리상태
                }

                if (n < cLen) {
                  text += `\n\n`
                }
                n++
              })
            }

            if (monthContent[i].month !== this.start.month) {
              // 이전달이거나 다음달인 경우 바탕색을 회색으로
              tBody.push({ text, style: 'tableBodyPast' }) // !! 내용을 넣는다.
            } else {
              // 현재달
              tBody.push({ text, style: 'tableBody' }) // !! 내용을 넣는다.
            }

            // 중요: 주의 끝(금요일 or 토요일)이면 개행(tr)을 한다.
            if ((i + 1) % weekLen === 0) {
              tbl.table.body.push(tBody) // 한주의 tr에 해당하는 tBody 배열을 테이블에 넣는다.
              tBody = [] // tBody 는 초기화 해야한다!
            }
          }
          // -- End of For Loop --

          // !! set content data
          let content = [
            { text: pdfHeaderTitle, style: 'header' },
            tbl
          ]
          this.pdfDoc.content = content
          //
        } else { // # 일간 Start
          // 중요: 일간이면 세로로
          this.pdfDoc.pageOrientation = 'portrait'

          // 선택일의 한글로된 요일 구하기
          const hYoil = this.yoilHan[this.$moment(this.focus).weekday()]

          // @: 헤더 타이틀
          const pdfHeaderTitle = `${this.title}(${hYoil}) 일정`
          // @: 작성일
          // const workDate = `작성: ${this.$moment().format('YYYY.MM.DD HH:mm')}`

          // * 선택주의 데이터에서 오늘의 데이터(배열)만 패칭하기!
          // console.log(this.datas[this.focus])
          const todayData = this.datas[this.focus]

          // * 해당 일자의 데이터
          let dayContent = []
          let num = 1
          todayData.forEach(item => {
            let text = ''
            let border = [false, false, false, false]

            // 맨 앞쪽 중요표시등 처리
            text = `\n${num}) ${item.isStar ? '★ ' : ''} `

            // @:구분:@: 앞대가리
            if (item.vtype === '221') {
              // # 업무
              // text += `${item.tm && item.tm !== '00:00' ? `${item.tm} ` : ''}`
              text += `${item.tm && !item.noTime ? `${item.tm} ` : ''}` // 시간: 시간없음이 아닌 경우
              text += `【${item.gubun1}】${item.str2 ? ` ${item.str2}` : ''}${item._place_time_ ? ` ${item._place_time_}` : ''}`
              text += ` #${item.gubun2}` // 관리상태
              text += `${item.str1 ? `\n${item.str1}` : ''}` // 내용

              //
            } else if (item.vtype === '211') {
              // # 기일
              // text += `${item.tm && item.tm !== '00:00' ? `${item.tm} ` : ''}`
              text += `${item.tm && !item.noTime ? `${item.tm} ` : ''}` // 시간: 시간없음이 아닌 경우
              // <b>..</b> 태그를 지워야 하네 젠장.. 왜 이렇게 해가지고.. 쩝
              item._place_time_ = item._place_time_.replace('<b>', '').replace('</b>', '')
              text += `${item.str1}${item._place_time_ ? ` ${item._place_time_} ` : ''}`
              text += `${item.str2 ? `[${item.str2}]` : ''}` // 결과
              text += `${item._chk_end_ ? `[${item._chk_end_}]` : ''}` // 종국확인
              text += `${item.gubun2 ? `#${item.gubun2}` : ''}` // 예정
              //
            } else if (item.vtype === '212') {
              // # 기한
              text += item.str1
              text += `${item.str2 ? ` (${item.str2})` : ''}` // 대상자
              //
            } else if (item.vtype === '214') {
              // # 제출
              // * [2022.9.20 추가]제출결과 추가
              text += `【${item.gubun1}】 ${item.str1}`
              text += `${item.str2 ? `\n${item.str2}` : ''}` // 결과
              //
            } else if (item.vtype === '215') {
              // # 송달
              text += `【${item.gubun1}】 ${item.str1}`
              text += `${item.date2 && item.str2 ? `\n${strDateFormat(item.date2)} ${item.str2}` : ''}` // 결과
              //
            } else if (item.vtype === '218') {
              // # 종국
              text += `【${item.gubun1}】 ${item.view1}`
              text += `${item.view2 ? ` ${item.view2}` : ''}`
              //
            } else if (item.vtype === '241') {
              // # 수금
              text += `【수금】 ${item.gubun2 === '미발행' ? '[미발행] ' : ''}`
              text += `${item.str2 ? `￦${numberFormat(item.str2)} ` : ''}${item.rInfo ? ` (${item.rInfo}) ` : ''}`
              text += `${item._sugum_delay_ ? ` (${item._sugum_delay_})` : ''}` // 수금지체
              text += `${item.gubun3 ? ` #${item.gubun3}` : ''}` // 관리상태
              text += `${item.str1 ? `\n${item.str1}` : ''}` // 내용
              //
            } else {
              // # 그외: 명령, 제출, 공고, 접수, 내역
              text += `【${item.gubun1}】 ${item.str1}`
            }

            // @:구분:@: 연계된 부모정보 - 한줄 밑으로
            if (item.pType === 1) {
              // # 소송
              text += '\n∽ '
              text += `${item.pInfo.courtName} ${item.pInfo.caseNum}${item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : ''}`
              text += ` ${item._dcl_}${item._dcl_ && item._dop_ ? `/${item._dop_}` : item._dop_} `
            } else if (item.pType === 2) {
              // # 자문
              text += '\n∽ '
              text += `${item.pInfo.manageNum}`
              text += ` ${item._dcl_}${item._dcl_ && item._dop_ ? `/${item._dop_}` : item._dop_} `
            } else if (item.pType === 3) {
              // # 인명부
              text += '\n∽ '
              text += `${item.pInfo.name} `
            } else {
              // # 업무인 경우: 독립업무 뷰
              text += item.vtype === '221' ? `\n∽ [독립업무] ` : ''
            }

            // # 관리그룹 & 팀
            text += `${item._groups_ ? ` - ${item._groups_}` : ''}${item.teamName ? ` [${item.teamName}]` : ''}`

            text += `\n`
            dayContent.push([ { text, border } ])
            num++
          })
          // End of ForEach Loop --

          // * set content data
          let content = [
            { text: pdfHeaderTitle, style: 'header' },
            // { text: workDate, style: 'subheader' },
            viewType3(dayContent)
          ]
          this.pdfDoc.content = content
          //
        }
        // # End of 일간

        // * 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()

        // shut off overlay
        sleep(350).then(() => { this.overlay = false })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/** 참고: 일정에서만 사용하는 스타일이라 공통으로 사용하는 App.vue 로 빼지 않았다. */
/* v-calendar 헤더 css 변경 */
.v-calendar-weekly__head {
  min-height: 30px;
  line-height: 100%;
  border-bottom: 1px solid #ededed;
}
/* 월간 일정 > 요일 - 폰트사이즈 11로 조정 원래 font-size는 10pt */
.v-calendar-weekly__head-weekday {
  padding: 10px 4px;
  font-size: 11pt;
  background-color: #fff;
}
/* 일간 > 요일 폰트 사이즈 12pt 로 조정 */
.v-calendar-daily_head-weekday {
  font-size: 12pt !important;
}
/* 일요일 ~ 토요일 폰트색상 - 원래 일,토요일의 폰트색상을 달리하려 하였으나 월~금 모드 사용 때문에 폐지 */
.v-calendar-weekly__head-weekday:nth-child(1) {
  color: #000000 !important; /*#ff5252 !important;*/
  caret-color: #000000 !important; /*#ff5252 !important;*/
}
.v-calendar-weekly__head-weekday:nth-child(2) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(3) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(4) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(5) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(6) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(7) {
  color: #000000 !important; /*#1976d2 !important;*/
  caret-color: #000000 !important; /*#1976d2 !important;*/
}
/* 지난월,다음월 바탕색 */
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: #ffffff;
}
/* 지난월 요일색 */
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: #000000;
}
/* 주간,일간의 요일 색상을 검정으로 고정시킨다 */
.theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday,
.theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label {
  color: #000000 !important;
}
/* !! 좌측 헤더와 바디를 아예 없앤다 */
.v-calendar-daily__intervals-head {
  display: none;
}
.v-calendar-daily__intervals-body {
  display: none;
}
/* !! 얘만 없애면 interval 섹션이 전부 안보인다. 되는구나 */
.v-calendar-daily__body {
  display: none;
}
/* !! 주간/일간의 날짜라벨을 없앤다. */
.v-calendar-daily_head-day-label {
  display: none;
}
/* 억지로 바꾸는 방법은 있다만.. */
/* .v-calendar-daily__interval:nth-child(4) {
  height: 100px !important;
}
.v-calendar-daily__day-interval:nth-child(4) {
  height: 100px !important;
} */
/* .v-calendar-daily__intervals-body:not(.v-calendar-daily__interval) {
  height: 40px !important;
}
.v-calendar-daily__day.v-present:not(.v-calendar-daily__day-interval) {
  height: 40px !important;
} */
/* !! 이건 된다 */
/* .v-calendar-daily__day-interval {
  padding: 3px;
  height: auto !important;
  min-height: 40px;
  overflow: visible !important;
} */
</style>
